import React, { Component } from 'react';
import _ from 'lodash';
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { browserHistory } from 'react-router';
import Select from 'react-select/lib/Async';
import SelectInput, { components } from 'react-select';
import DropZone from 'react-dropzone';
import FileSaver from 'file-saver';
import Lib from '../../../commons/lib';
import { DatePicker, FileCard, PhoneCard } from '../../../commons/controls';
import Services from './services';
import { ProgressButton } from '../../../commons/ProgressButton';
import NumberFormatter from '../../../commons/NumberFormatter';
import SlaveAvInput from '../../../commons/SlaveAvInput';

/**
 * Osztály partner űrlap létrehozására.
 * @extends Component
 */
class PartnertorzsUrlap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentId: null,
      modositoStatus: false,
      modositoObj: null,
      dataLoaded: false,
      partnercsopId: '',
      mezokotelezosegek: [],
      cegkotelezosegek: [],
      maganszemelykotelezosegek: [],
      isCeg: false,
      isMaganszemely: false,
      hasSzallitasiCim: false,
      hasSzamlazasiCim: false,

      alapertelmezettOrszagValue: {},

      telepulesMezoInaktiv: true,
      telepulesSelect: false,
      telepulesMezo: [],
      orszagValue: {},
      telepulesid: '',

      telepulesSzallMezoInaktiv: true,
      telepulesSzallSelect: false,
      telepulesSzallMezo: [],
      orszagSzallValue: {},
      szalltelepulesid: '',

      telepulesSzamMezoInaktiv: true,
      telepulesSzamSelect: false,
      telepulesSzamMezo: [],
      orszagSzamValue: {},
      szamtelepulesid: '',

      modalPhone: false,
      modalPhoneTitle: '-',
      modalPhoneButtonText: '-',
      phoneButtonLoading: false,
      formModalTipus: '',
      formModalOrszaghivo: '',
      formModalTelefonszam: '',

      partnercsoportOptions: [],
      bankAdatok: [],
      partnerTipusOptions: [],
      szallitovevoOptions: [],
      statuszOptions: [],
      nemOptions: [],
      orszagOptions: [],
      telefonTipusOptions: [],
      telefonszamok: [],

      hozzajarulas: [],
      jogalap: null,
      cegadat: null,
      modalHozzajarulModal: false,
      hozzajarulasModalAdatok: [],

      mentesButtonLoading: false,
      files: [],

      isCegMaganszemelyre: false,
      isMaganszemelyCegre: false,
      isLathato: true,
      hasHazszam: false,
      hasHrsz: false,
      hasSzallHazszam: false,
      hasSzallHrsz: false,
      hasSzamHazszam: false,
      hasSzamHrsz: false,
      szuletesiido: null,
      disabledHozzajarulasIds: [],
    };

    this.disabledHozzajarulasIds = [];
    this.setOrszagDefaultValue();
    this.getTelepulesEnum();
    Services.listEnumPartner((err, res) => {
      if (!err) {
        let partnerTipusOptions = res[0][0].map(e => (
          <option key={e.name} value={e.name}>
            {e.caption}
          </option>
        ));

        let szallitovevoOptions = res[0][1].map(e => (
          <option key={e.name} value={e.name}>
            {e.caption}
          </option>
        ));

        let statuszOptions = res[0][2].map(e => (
          <option key={e.name} value={e.name}>
            {e.caption}
          </option>
        ));

        let nemOptions = res[0][3].map(e => (
          <option key={e.name} value={e.name}>
            {e.caption}
          </option>
        ));

        this.setState({
          partnerTipusOptions: partnerTipusOptions,
          szallitovevoOptions: szallitovevoOptions,
          statuszOptions: statuszOptions,
          nemOptions: nemOptions
        });
      }
    });

    Services.initPartnerCsop((err, res) => {
      if (!err) {
        let cegkotelezosegek = [];
        let maganszemelykotelezosegek = [];
        res[0].map((item) => {
          for (let [key, value] of Object.entries(item.cegkotelezoseg)) {
            cegkotelezosegek.push({ kotelezosegnev: key, kotelezosegvalue: value, id: item.id });
          }
          for (let [key, value] of Object.entries(item.maganszemelykotelezoseg)) {
            maganszemelykotelezosegek.push({ kotelezosegnev: key, kotelezosegvalue: value, id: item.id });
          }
        });
        let partnercsoportOptions = res[0].map(e => (
          <option key={e.id} value={e.id}>
            {e.nev}
          </option>
        ));
        this.setState({
          partnercsoportOptions: partnercsoportOptions,
          cegkotelezosegek: cegkotelezosegek,
          maganszemelykotelezosegek: maganszemelykotelezosegek
        }, () => {
          this.getData();
        });
      } else {
        // console.log('Error: ', err);
      }
    });
  }

  componentDidMount() {
    this.onMount();
  }

  onMount = () => {
    if (this.props.route.modositas === 'true') {
      this.setState({ modositoStatus: true, isLathato: false });
    }
  }

  getData = () => {
    // alert(new URLSearchParams(window.location.search));
    let params = new URL(window.location.href).searchParams;
    let id = params.get('id');
    this.setState({ mezokotelezosegek: [] });
    let kotelezosegek = [];

    if (this.props.route.modositas === 'true') {
      if (id) {
        Services.getPartner(id, (err, res) => {
          if (!err) {
            let files = res[0].objectparam;
            let newTajszam;
            let newAdoszam;
            if (res[0].adoszam) {
              // newAdoszam = res[0].adoszam;
              newAdoszam = NumberFormatter.addHyphensToString(res[0].adoszam);
            }
            if (res[0].tajszam) {
              newTajszam = res[0].tajszam.slice(0, 3) + ' ' + res[0].tajszam.slice(3, 6) + ' ' + res[0].tajszam.slice(6, 9);
            }
            res[0].adoszam = res[0].adoszam ? newAdoszam : null;
            res[0].tajszam = res[0].tajszam ? newTajszam : null;
            if (res[0].hozzajarulas) {
              let cegadatids = []; // hozzjárulások cegadatid-i, az aktívos lekérdezéshez
              let jogalapids = []; // hozzájárulások jogalapid-i, az aktívos lekérdezéshez
              res[0].hozzajarulas.forEach((item) => {
                cegadatids.push(item.cegadatid);
                jogalapids.push(item.jogalapid);
                if (item.vege) {
                  let id = item.id;
                  this.setState((prevState) => ({disabledHozzajarulasIds: [...prevState.disabledHozzajarulasIds, id] }))
                  this.disabledHozzajarulasIds.push(item.id);
                }
              });

              Services.getCegadatList(cegadatids, (err, res) => {
                if (!err) {
                  this.setState({ cegadat: res[0] });
                }
              });
    
              Services.getJogalapList(jogalapids, (err, res) => {
                if (!err) {
                  this.setState({ jogalap: res[0] });
                }
              });
    
            }
            if (res[0].hazszam) {
              this.setState({ hasHazszam: true });
            }
            if (res[0].szallhazszam) {
              this.setState({ hasSzallHazszam: true });
            }
            if (res[0].szamhazszam) {
              this.setState({ hasSzamHazszam: true });
            }
            if (res[0].hrsz) {
              this.setState({ hasHazszam: true });
            }
            if (res[0].szallhrsz) {
              this.setState({ hasSzallHazszam: true });
            }
            if (res[0].szamhrsz) {
              this.setState({ hasSzamHazszam: true });
            }
            if (res[0].tipus.name === 'CEG') {
              for (let [key, value] of Object.entries(res[0].partnercsoportentity.cegkotelezoseg)) {
                kotelezosegek.push({ kotelezosegnev: key, kotelezosegvalue: value });
              }
            }
            if (res[0].tipus.name === 'MAGANSZEMELY') {
              for (let [key, value] of Object.entries(res[0].partnercsoportentity.maganszemelykotelezoseg)) {
                kotelezosegek.push({ kotelezosegnev: key, kotelezosegvalue: value });
              }
            }
            this.setState({
              mezokotelezosegek: kotelezosegek,
              currentId: id,
            }, () => {
              if (this.state.mezokotelezosegek.length !== 0) {
                let o = this.state.alapertelmezettOrszagValue;

                this.setState({
                  partnercsopId: res[0].partnercsoportentity.id,
                  telefonszamok: res[0].telefonszamok ? res[0].telefonszamok : [],
                  modositoObj: res[0],
                  bankAdatok: res[0].bankszamlaszamok ? res[0].bankszamlaszamok : [],
                  hozzajarulas: res[0].hozzajarulas ? res[0].hozzajarulas : [],
                  dataLoaded: true,
                  isCeg: res[0].tipus && res[0].tipus.name === 'CEG',
                  isMaganszemely: res[0].tipus && res[0].tipus.name === 'MAGANSZEMELY',
                  orszagValue: (res[0].orszag && res[0].orszag.orszagid) ? { orszagid: res[0].orszagid, nev: res[0].orszagnev } : o,
                  orszagSzallValue: (res[0].szallOrszag && res[0].szallOrszag.orszagid) ? { orszagid: res[0].szallOrszag.orszagid, nev: res[0].szallOrszag.nev } : o,
                  orszagSzamValue: (res[0].szamOrszag && res[0].szamOrszag.orszagid) ? { orszagid: res[0].szamOrszag.orszagid, nev: res[0].szamOrszag.nev } : o,

                  telepulesSelect: (res[0].orszag && res[0].orszagid) ? res[0].orszag.nev.toUpperCase() === 'MAGYARORSZÁG' : o.keresheto,
                  telepulesSzallSelect: (res[0].szallOrszag && res[0].szallorszagid) ? res[0].szallorszagnev.toUpperCase() === 'MAGYARORSZÁG' : o.keresheto,
                  telepulesSzamSelect: (res[0].szamOrszag && res[0].szamorszagid) ? res[0].szamorszagnev.toUpperCase() === 'MAGYARORSZÁG' : o.keresheto,

                  telepulesid: res[0].telepulesid,
                  szalltelepulesid: res[0].szalltelepulesid,
                  szamtelepulesid: res[0].szamtelepulesid,

                  telepulesMezoInaktiv: true,
                  telepulesSzallMezoInaktiv: true,
                  telepulesSzamMezoInaktiv: true,
                  files: files,
                  isLathato: false,
                  hasSzamlazasiCim: (res[0].tipus && res[0].tipus.name === 'MAGANSZEMELY' ? this.getRequired('m_szamcim') : this.getRequired('c_szamcim')) ? true : res[0].szamcimeltero,
                  hasSzallitasiCim: (res[0].tipus && res[0].tipus.name === 'MAGANSZEMELY' ? this.getRequired('m_szallcim') : this.getRequired('c_szallcim')) ? true : res[0].szallcimeltero,
                  szuletesiido: res[0] && res[0].szuletesiido ? new Date(res[0].szuletesiido) : null
                }, () => {
                  this.state.modositoObj.szallcimeltero = (this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) ? true : res[0].szallcimeltero;
                  this.state.modositoObj.szamcimeltero = (this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) ? true : res[0].szamcimeltero;
                });
              }
            });
            this.getTelepelusek(res[0].iranyitoszam, (telepulesek) => {
              if (telepulesek.length > 0) {
                this.setState({
                  telepulesMezoInaktiv: false,
                  telepulesMezo: telepulesek
                });
              } else {
                this.setState({ telepulesMezo: [] });
              }
            });

            this.getTelepelusek(res[0].szalliranyitoszam, (telepulesek) => {
              if (telepulesek.length > 0) {
                this.setState({
                  telepulesSzallMezoInaktiv: false,
                  telepulesSzallMezo: telepulesek
                });
              } else {
                this.setState({ telepulesSzallMezo: [] });
              }
            });

            this.getTelepelusek(res[0].szamiranyitoszam, (telepulesek) => {
              if (telepulesek.length > 0) {
                this.setState({
                  telepulesSzamMezoInaktiv: false,
                  telepulesSzamMezo: telepulesek
                });
              } else {
                this.setState({ telepulesSzamMezo: [] });
              }
            });
          }
        });
      }
    } else {
      let obj = {};
      obj.statusz = 'AKTIV';
      this.setState({
        modositoObj: obj,
        dataLoaded: true,
        partnercsopId: this.state.partnercsoportOptions[0].props.value,
      });

      Services.getJogalapList(null, (err, res) => {
        if (!err) {
          this.setState({ jogalap: res[0] });
        }
      });

      Services.getCegadatList(null, (err, res) => {
        if (!err) {
          this.setState({ cegadat: res[0] }, () => {
            this.getKotelezosegek(this.state.partnercsopId);
            this.setState({ mezokotelezosegek: kotelezosegek });
          }
          );
        }
      });
    }
}

  /** Települések lekérdezése irányítószám alapján */
  getTelepelusek = (irszam, callback) => {
    Services.listTelepules(irszam, (err, res) => {
      if (!err) {
        callback(res[0]);
      }
    });
  }

  getTelepulesEnum = () => {
    Services.listTelefonEnum((err, res) => {
      if (!err) {
        let telefonTipusOptions = res[0][1].map(e => (
          <option key={e.name} value={e.name}>
            {e.caption}
          </option>
        ));
        this.setState({
          telefonTipusOptions: telefonTipusOptions,
          orszagOptions: res[0][0]
        });
      }
    });
  }

  setOrszagDefaultValue = () => {
    Services.getAlapertelmezettOrszag((err, res) => {
      if (!err) {
        if (res[0]) {
          this.setState({
            orszagValue: { orszagid: res[0].orszagid, nev: res[0].nev },
            orszagSzallValue: { orszagid: res[0].orszagid, nev: res[0].nev },
            orszagSzamValue: { orszagid: res[0].orszagid, nev: res[0].nev },
            alapertelmezettOrszagValue: { orszagid: res[0].orszagid, nev: res[0].nev, keresheto: res[0].keresheto },
            telepulesSelect: res[0].keresheto,
            telepulesSzallSelect: res[0].keresheto,
            telepulesSzamSelect: res[0].keresheto,
          });
        }
      }
    });
  }

  /** Objektumot bejárva az üres stringeket NULL-ra állítja */
  emptyToNull = (obj) => {
    if (!obj || typeof obj !== 'object') return;
    for (let p in obj) {
      if (typeof obj[p] === 'object' && obj[p] !== null) {
        this.emptyToNull(obj[p]);
      } else if (obj.hasOwnProperty(p)) {
        if (obj[p] === '' || obj[p] === undefined) {
          obj[p] = null;
        }
      }
    }
  }

  loadOrszagOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listOrszag(input, (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  handleChangeOrszag = (value) => {
    if (value && value.length !== 0) {
      this.setState({ telepulesSelect: value.keresheto, orszagValue: { orszagid: value.orszagid, nev: value.nev }, telepulesMezo: [] }, () => {
        if (this.state.telepulesSelect) {
          this.handleIrszamChange(null, this.refs.iranyitoszam.state.value);
        } else {
          this.setState({ telepulesid: '' });
        }
      });
    } else {
      this.setState({ orszagValue: { orszagid: '', nev: '' }, telepulesMezoInaktiv: true, telepulesid: '' });
    }
  }

  handleChangeSzallOrszag = (value) => {
    if (value && value.length !== 0) {
      this.setState({ telepulesSzallSelect: value.keresheto, orszagSzallValue: { orszagid: value.orszagid, nev: value.nev }, telepulesSzallMezo: [] }, () => {
        if (this.state.telepulesSzallSelect) {
          this.handleIrszamSzallChange(null, this.refs.iranyitoszamSzall.state.value);
        } else {
          this.setState({ szalltelepulesid: '' });
        }
      });
    } else {
      this.setState({ orszagSzallValue: { orszagid: '', nev: '' }, telepulesSzallMezoInaktiv: true, szalltelepulesid: '' });
    }
  }

  handleChangeSzamOrszag = (value) => {
    if (value && value.length !== 0) {
      this.setState({ telepulesSzamSelect: value.keresheto, orszagSzamValue: { orszagid: value.orszagid, nev: value.nev }, telepulesSzamMezo: [] }, () => {
        if (this.state.telepulesSzamSelect) {
          this.handleIrszamSzamChange(null, this.refs.iranyitoszamSzam.state.value);
        } else {
          this.setState({ szamtelepulesid: '' });
        }
      });
    } else {
      this.setState({ orszagSzamValue: { orszagid: '', nev: '' }, telepulesSzamMezoInaktiv: true, szamtelepulesid: '' });
    }
  }

  handleTelepulesChange = (e) => {
    if (e.target.value && e.target.value.length !== 0) {
      this.setState({ telepulesid: e.target.value });
    } else {
      this.setState({ telepulesid: '' });
    }
  }

  handleSzallTelepulesChange = (e) => {
    if (e.target.value && e.target.value.length !== 0) {
      this.setState({ szalltelepulesid: e.target.value });
    } else {
      this.setState({ szalltelepulesid: '' });
    }
  }

  handleSzamTelepulesChange = (e) => {
    if (e.target.value && e.target.value.length !== 0) {
      this.setState({ szamtelepulesid: e.target.value });
    } else {
      this.setState({ szamtelepulesid: '' });
    }
  }

  handleIrszamChange = (event, value) => {
    this.setState({ telepulesMezoInaktiv: true });
    if (this.state.telepulesSelect) {
      if (value && value.length >= 4) {
        /** Adott irányítószámhoz tartozó települések lekérdezése. */
        Services.listTelepules(value, (err, res) => {
          if (!err) {
            if (res[0].length > 0) {
              this.setState({
                telepulesMezoInaktiv: false,
                telepulesMezo: res[0],
              });
            } else {
              this.setState({
                telepulesMezo: [],
              });
              this.props.route.globals.addWarning('Ehhez az irányítószámhoz nem tartozik település!');
            }
          }
        });
      } else {
        this.setState({
          telepulesMezo: [],
          telepulesid: ''
        });
      }
    }
  }

  handleIrszamSzallChange = (event, value) => {
    this.setState({ telepulesSzallMezoInaktiv: true });
    if (this.state.telepulesSzallSelect) {
      if (value && value.length >= 4) {
        /** Adott irányítószámhoz tartozó települések lekérdezése. */
        Services.listTelepules(value, (err, res) => {
          if (!err) {
            if (res[0].length > 0) {
              this.setState({
                telepulesSzallMezoInaktiv: false,
                telepulesSzallMezo: res[0],
              });
            } else {
              this.setState({
                telepulesSzallMezo: [],
              });
              this.props.route.globals.addWarning('Ehhez az irányítószámhoz nem tartozik település!');
            }
          }
        });
      } else {
        this.setState({
          telepulesSzallMezo: [],
          szalltelepulesid: ''
        });
      }
    }
  }

  handleIrszamSzamChange = (event, value) => {
    this.setState({ telepulesSzamMezoInaktiv: true });
    if (this.state.telepulesSzamSelect) {
      if (value && value.length >= 4) {
        /** Adott irányítószámhoz tartozó települések lekérdezése. */
        Services.listTelepules(value, (err, res) => {
          if (!err) {
            if (res[0].length > 0) {
              this.setState({
                telepulesSzamMezoInaktiv: false,
                telepulesSzamMezo: res[0],
              });
            } else {
              this.setState({
                telepulesSzamMezo: [],
              });
              this.props.route.globals.addWarning('Ehhez az irányítószámhoz nem tartozik település!');
            }
          }
        });
      } else {
        this.setState({
          telepulesSzamMezo: [],
          szamtelepulesid: ''
        });
      }
    }
  }

  addTelefonszamRow = () => {
    let newTelefonszamok = this.state.telefonszamok;
    newTelefonszamok.push({
      id: null,
      tipus: {
        name: '',
        caption: '',
      },
      orszag: {
        name: '',
        caption: '',
      },
      szam: '',
      elsodleges: false
    });
    this.setState({ telefonszamok: newTelefonszamok });
  }

   clone = (obj) => {
     if (obj == null || typeof (obj) !== 'object') return obj;
     let temp = new obj.constructor();
     for (let key in obj) temp[key] = this.clone(obj[key]);
     return temp;
   }

   handleInvalidSubmit = (event, errors, values) => {
     if (errors.length !== 0){
       let errorFieldId = errors[0];
       let errorField = document.getElementById(errorFieldId);
       errorField.focus();
     }
   }

  mentes = (event, partnerObj) => {
    this.setState({ mentesButtonLoading: true });
    let submitTelefonszam = this.clone(this.state.telefonszamok);
    let submitObj = partnerObj;
    let submitHozzajarulas = JSON.parse(JSON.stringify(this.state.hozzajarulas));

    if (partnerObj.adoszam && submitObj.adoszam != null) submitObj.adoszam = NumberFormatter.removeHyphensFromString(submitObj.adoszam);
    if (submitObj.tajszam && submitObj.tajszam != null) submitObj.tajszam = submitObj.tajszam.replace(/ /g, '');
    if (submitObj.kozossegiadoszam && submitObj.kozossegiadoszam != null) submitObj.kozossegiadoszam = submitObj.kozossegiadoszam.replace(/-/g, '');

    submitObj.telepulesid = this.state.telepulesid;
    submitObj.szalltelepulesid = this.state.szalltelepulesid;
    submitObj.szamtelepulesid = this.state.szamtelepulesid;

    if (this.state.modositoStatus) {
      submitObj.orszag = this.state.orszagValue;
      if (this.state.isMaganszemely && this.getRequired('m_szallcim') === null) {
        submitObj.szallcimeltero = false;
      }
      if (this.state.hasSzallitasiCim) {
        submitObj.szallOrszag = this.state.orszagSzallValue;
      }
      if (this.state.isMaganszemely && this.getRequired('m_szamcim') === null) {
        submitObj.szamcimeltero = false;
      }
      if (this.state.hasSzamlazasiCim) {
        submitObj.szamOrszag = this.state.orszagSzamValue;
      }
      if (this.state.isMaganszemely && partnerObj.szuletesiido == '' && this.getRequired('m_szulido')) {
        this.validateCustomComponent(null, null, 'szuletesiido', 'szulidoerror', 'szulidolabel', 'm_szulido');
      }
      submitObj.id = this.state.currentId;
      // submitObj.partnercsoportentity = { id: this.state.partnercsopId };
      submitObj.partnercsoportid = this.state.partnercsopId;
      submitObj.bankszamlaszamok = this.state.bankAdatok;
      submitObj.objectparam = this.state.files;
      submitObj.telefonszamok = [];
      submitTelefonszam.forEach((item) => {
        if (item.orszag && item.orszag.name) {
          item.orszag = item.orszag.name.toUpperCase();
        }
        if (item.tipus && item.tipus.name) {
          item.tipus = item.tipus.name;
        }
        submitObj.telefonszamok.push(item);
      });

      let hozzajarulas = [{ hozzajarulas: [] }];
      submitHozzajarulas.forEach((item) => {
        if (item.jogalapid === -1) {
          // this.props.route.globals.addError('Hozzájáruláshoz jogalap megadása kötelező!');
        }
        item.jogalapid = parseInt(item.jogalapid, 10);
        item.cegadatid = parseInt(item.cegadatid, 10);
        item.hozzajarul = false;
        if (!item.id) hozzajarulas[0].hozzajarulas.push(item);
      });
      submitObj.hozzajarulas = submitHozzajarulas;
      if (!submitObj.hozzajarulas.length) { submitObj.hozzajarulas = null; }
      if (!submitObj.telefonszamok.length) { submitObj.telefonszamok = null; }
      if (!submitObj.bankszamlaszamok.length) { submitObj.bankszamlaszamok = null; }
      if (!this.state.telepulesMezo.length) delete submitObj.telepulesid;
      this.emptyToNull(submitObj);

      Services.editPartner(submitObj, (err) => {
        if (!err) {
          this.setState({ mentesButtonLoading: false });
          // this.props.route.globals.addSuccess('Partner módosítva!');
          if (hozzajarulas[0].hozzajarulas.length > 0 && submitObj.tipus === 'MAGANSZEMELY') {
            this.setState({ hozzajarulasModalAdatok: hozzajarulas }, () => {
              if (this.state.hozzajarulasModalAdatok[0].hozzajarulas.length) {
                this.toggleHozzajarulModal();
              } else {
                let mentesiAdatok = this.state.hozzajarulasModalAdatok[0].hozzajarulas;
                this.state.modositoObj.hozzajarulas.forEach((item) => {
                  if (item.id) mentesiAdatok.push(item);
                });
                Services.setHozzajarul(this.state.modositoObj.id, false, mentesiAdatok, (err2) => {
                  if (!err2) {
                    this.props.route.globals.addSuccess('Partner módosítva!');
                    this.navToList();
                  }
                });
              }
            });
          } else {
            this.setState({ mentesButtonLoading: false });
            this.navToList();
            if (this.state.files.length === 0) {
              this.validateCustomComponent(null, null, 'dropzone', 'dropzoneerror', 'dropzonelabel', this.state.isCeg ? 'c_fajl' : 'm_fajl');
            }
          }
        } else {
          if (this.state.files.length === 0) {
            this.validateCustomComponent(null, null, 'dropzone', 'dropzoneerror', 'dropzonelabel', this.state.isCeg ? 'c_fajl' : 'm_fajl');
          }
          this.setState({ mentesButtonLoading: false });
        }
      });
    } else {
      this.setState({ mentesButtonLoading: true });
      // submitObj.partnercsoportentity = { id: this.state.partnercsopId };
      submitObj.partnercsoportid = this.state.partnercsopId;

      submitObj.orszag = this.state.orszagValue;
      if (this.state.isMaganszemely && this.getRequired('m_szallcim') === null) {
        submitObj.szallcimeltero = false;
      }
      if (this.state.hasSzallitasiCim) {
        submitObj.szallOrszag = this.state.orszagSzallValue;
      }
      if (this.state.isMaganszemely && this.getRequired('m_szamcim') === null) {
        submitObj.szamcimeltero = false;
      }
      if (this.state.hasSzamlazasiCim) {
        submitObj.szamOrszag = this.state.orszagSzamValue;
      }
      if (this.state.isMaganszemely && partnerObj.szuletesiido == '' && this.getRequired('m_szulido')) {
        this.validateCustomComponent(null, null, 'szuletesiido', 'szulidoerror', 'szulidolabel', 'm_szulido');
      }
      if (this.state.hasSzallitasiCim) {
        submitObj.szallOrszag = this.state.orszagSzallValue;
      }
      if (this.state.hasSzamlazasiCim) {
        submitObj.szamOrszag = this.state.orszagSzamValue;
      }
      submitObj.bankszamlaszamok = this.state.bankAdatok;
      submitObj.objectparam = this.state.files;
      submitObj.telefonszamok = [];
      submitTelefonszam.forEach((item) => {
        if (item.orszag && item.orszag.name) {
          item.orszag = item.orszag.name.toUpperCase();
        }
        if (item.tipus && item.tipus.name) {
          item.tipus = item.tipus.name;
        }
        submitObj.telefonszamok.push(item);
      });

      if (!submitObj.telefonszamok.length) { submitObj.telefonszamok = null; }
      if (!submitObj.bankszamlaszamok.length) { submitObj.bankszamlaszamok = null; }
      // if (!this.state.telepulesMezo.length) delete submitObj.telepulesid;
      this.emptyToNull(submitObj);

      submitObj.hozzajarulas = [];
      this.state.hozzajarulas.forEach((item, idx) => {
        if (item.jogalapid == -1) {
          this.validateCustomComponent(null, null, 'jogalap' + idx, 'jogalaperror', 'jogalaplabel', 'm_gdpr');
        } else {
          if (item.kezdete === '') item.kezdete = this.dateTime(new Date());
          item.jogalapid = parseInt(item.jogalapid);
          item.cegadatid = parseInt(item.cegadatid);
          submitObj.hozzajarulas.push(item);
        }
      });
      if (!submitObj.hozzajarulas.length) { submitObj.hozzajarulas = null; }
      Services.addPartner(submitObj, (err, res) => {
        if (!err) {
          this.setState({ mentesButtonLoading: false });
          if (submitObj.tipus === 'MAGANSZEMELY') {
            this.setState({ hozzajarulasModalAdatok: res });
            this.toggleHozzajarulModal();
          } else {
            this.props.route.globals.addSuccess('Partner felvéve!');
            this.navToList();
            if (this.state.files.length === 0) {
              this.validateCustomComponent(null, null, "dropzone", "dropzoneerror", "dropzonelabel", this.state.isCeg ? "c_fajl" : "m_fajl");
            }
          }
        } else {
          if (this.state.files.length === 0) {
            this.validateCustomComponent(null, null, "dropzone", "dropzoneerror", "dropzonelabel", this.state.isCeg ? "c_fajl" : "m_fajl");
          }
          this.setState({ mentesButtonLoading: false });
        }
      });
    }
  }

  tipusChange = (e) => {
    let kotelezosegek = [];
    this.setState({ mezokotelezosegek: [] });
    if (e.target.value === 'CEG') {
      if (this.state.modositoObj && this.state.modositoStatus && (this.state.modositoObj.keresztnev && this.state.modositoObj.vezeteknev)) {
        this.setState({ isMaganszemelyCegre: true });
      }
      this.setState({
        isCeg: true,
        isMaganszemely: false,
      }, () => {
        kotelezosegek = this.state.partnercsopId ? this.getKotelezosegek(this.state.partnercsopId) : this.getKotelezosegek(this.state.partnercsoportOptions[0].props.value);
        this.setState({
          mezokotelezosegek: kotelezosegek,
          isLathato: false
        }, () => {
          if (this.getRequired('c_szallcim')) {
            if (this.props.route.modositas === 'true' && this.state.modositoObj) {
              this.state.modositoObj.szallcimeltero = true;
            }
            this.setState({ hasSzallitasiCim: true });
          }
          if (this.getRequired('c_szallcim') === false){
            if (this.props.route.modositas === 'true' && this.state.modositoObj) {
              this.state.modositoObj.szallcimeltero = false;
            }
            this.setState({ hasSzallitasiCim: false });
          }
          if (this.getRequired('c_szamcim')) {
            if (this.props.route.modositas === 'true' && this.state.modositoObj) {
              this.state.modositoObj.szamcimeltero = true;
            }
            this.setState({ hasSzamlazasiCim: true });
          }
          if (this.getRequired('c_szamcim') === false){
            if (this.props.route.modositas === 'true' && this.state.modositoObj) {
              this.state.modositoObj.szamcimeltero = false;
            }
            this.setState({ hasSzamlazasiCim: false });
          }
        });

      });

    }
    if (e.target.value === 'MAGANSZEMELY') {
      if (this.state.modositoObj && this.state.modositoStatus && (this.state.modositoObj.cegnev)) {
        this.setState({ isCegMaganszemelyre: true });
      }

      this.setState({
        isCeg: false,
        isMaganszemely: true,
      }, () => {
        kotelezosegek = this.state.partnercsopId ? this.getKotelezosegek(this.state.partnercsopId) : this.getKotelezosegek(this.state.partnercsoportOptions[0].props.value);
        this.setState({
          mezokotelezosegek: kotelezosegek,
          isLathato: false
        }, () => {
          this.setState({ hasSzamlazasiCim: false, hasSzallitasiCim: false });
          if (this.getRequired('m_szallcim')) {
            if (this.props.route.modositas === 'true' && this.state.modositoObj) {
              this.state.modositoObj.szallcimeltero = true;
            }
            this.setState({ hasSzallitasiCim: true });
          }
          if (this.getRequired('m_szallcim') === false){
            if (this.props.route.modositas === 'true' && this.state.modositoObj) {
              this.state.modositoObj.szallcimeltero = false;
            }
            this.setState({ hasSzallitasiCim: false });
          }
          if (this.getRequired('m_szamcim')) {
            if (this.props.route.modositas === 'true' && this.state.modositoObj) {
              this.state.modositoObj.szamcimeltero = true;
            }
            this.setState({ hasSzamlazasiCim: true });
          }
          if (this.getRequired('m_szamcim') === false){
            if (this.props.route.modositas === 'true' && this.state.modositoObj) {
              this.state.modositoObj.szamcimeltero = false;
            }
            this.setState({ hasSzamlazasiCim: false });
          }
        });
      });
    }
    if (!this.state.modositoStatus && this.state.hozzajarulas.length === 0) {
      if (this.state.hozzajarulas.length === 0) {
        this.addHozzajarulasAdatRow();
      }
      if (!this.getRequired('m_bankszamla') === null){
        this.addBankAdatRow();
      }
    }
  }

  handlePartnerCsoportChange = (e) => {
    this.setState({ mezokotelezosegek: [] });
    const target = e.target;
    const value = target.value;
    const name = target.name;
    let kotelezosegek = this.getKotelezosegek(value);
    this.setState({
      [name]: value
    });
    this.setState({ partnercsopId: value, isLathato: false, mezokotelezosegek: kotelezosegek }, () => {
      if (this.getRequired('m_szallcim')) {
        if (this.props.route.modositas === 'true' && this.state.modositoObj) {
          this.state.modositoObj.szallcimeltero = true;
        }
        this.setState({ hasSzallitasiCim: true });
      }
      if (this.props.route.modositas === 'true' && this.state.modositoObj && this.state.isMaganszemely && this.getRequired('m_szallcim') === false) {
        this.state.modositoObj.szallcimeltero = false;
        this.setState({ hasSzallitasiCim: false });
      }
      if (this.props.route.modositas === 'false' && this.state.isMaganszemely && this.getRequired('m_szallcim') === false) {
        this.setState({ hasSzallitasiCim: false });
      }
      if (this.getRequired('c_szallcim')) {
        if (this.props.route.modositas === 'true' && this.state.modositoObj) {
          this.state.modositoObj.szallcimeltero = true;
        }
        this.setState({ hasSzallitasiCim: true });
      }
      if (this.props.route.modositas === 'true' && this.state.modositoObj && this.state.isCeg && this.getRequired('c_szallcim') === false) {
        this.state.modositoObj.szallcimeltero = false;
        this.setState({ hasSzallitasiCim: false });
      }
      if (this.props.route.modositas === 'false' && this.state.isCeg && this.getRequired('c_szallcim') === false) {
        this.setState({ hasSzallitasiCim: false });
      }
      if (this.getRequired('m_szamcim')) {
        if (this.props.route.modositas === 'true' && this.state.modositoObj) {
          this.state.modositoObj.szamcimeltero = true;
        }
        this.setState({ hasSzamlazasiCim: true });
      }
      if (this.props.route.modositas === 'true' && this.state.modositoObj && this.state.isMaganszemely && this.getRequired('m_szamcim') === false) {
        this.state.modositoObj.szamcimeltero = false;
        this.setState({ hasSzamlazasiCim: false });
      }
      if (this.props.route.modositas === 'false' && this.state.isMaganszemely && this.getRequired('m_szamcim') === false) {
        this.setState({ hasSzamlazasiCim: false });
      }
      if (this.getRequired('c_szamcim')) {
        if (this.props.route.modositas === 'true' && this.state.modositoObj) {
          this.state.modositoObj.szamcimeltero = true;
        }
        this.setState({ hasSzamlazasiCim: true });
      }
      if (this.props.route.modositas === 'true' && this.state.modositoObj && this.state.isCeg && this.getRequired('c_szamcim') === false) {
        this.state.modositoObj.szamcimeltero = false;
        this.setState({ hasSzamlazasiCim: false });
      }
      if (this.props.route.modositas === 'false' && this.state.isCeg && this.getRequired('c_szamcim') === false) {
        this.setState({ hasSzamlazasiCim: false });
      }
    });
  }

  handleHszamChange = (e) => {
    this.setState({ hasHazszam: true, hasHrsz: false });
  }

  handleHrszChange = (e) => {
    this.setState({ hasHrsz: true, hasHazszam: false });
  }

  handleSzallHszamChange = (e) => {
    this.setState({ hasSzallHazszam: true, hasSzallHrsz: false });
  }

  handleSzallHrszChange = (e) => {
    this.setState({ hasSzallHrsz: true, hasSzallHazszam: false });
  }

  handleSzamHszamChange = (e) => {
    this.setState({ hasSzamHazszam: true, hasSzamHrsz: false });
  }

  handleSzamHrszChange = (e) => {
    this.setState({ hasSzamHrsz: true, hasSzamHazszam: false });
  }

  handleHozzajarulasDisplay = () => {
    let hozzajarulasElement = document.getElementById('hozzajar');
    let ujHozzajarGomb = document.getElementById('hozzajargomb');
    if (hozzajarulasElement && ujHozzajarGomb) {
      if (!this.state.modositoStatus && this.state.isCeg) {
        hozzajarulasElement.hidden = true;
        ujHozzajarGomb.hidden = true;
      }
      if (!this.state.modositoStatus && this.state.isMaganszemely) {
        hozzajarulasElement.hidden = false;
        ujHozzajarGomb.hidden = false;
      }
      if (this.state.modositoStatus) {
        if (this.state.isMaganszemelyCegre) {
          hozzajarulasElement.hidden = false;
          ujHozzajarGomb.hidden = true;
        }
        if (this.state.hozzajarulas.length !== 0) {
          hozzajarulasElement.hidden = false;
          ujHozzajarGomb.hidden = true;
        } else {
          hozzajarulasElement.hidden = true;
          ujHozzajarGomb.hidden = true;
        }
      }
      if (this.state.modositoStatus && this.state.isMaganszemely) {
        hozzajarulasElement.hidden = false;
        ujHozzajarGomb.hidden = false;
      }
    }
  }

  szallcimelteroChange = (e) => {
    if (e.target.value === 'true') {
      this.setState({ hasSzallitasiCim: true });
    } else if (e.target.value === 'false') {
      this.setState({ hasSzallitasiCim: false });
    }
  }

  szamlcimelteroChange = (e) => {
    if (e.target.value === 'true') {
      this.setState({ hasSzamlazasiCim: true });
    } else if (e.target.value === 'false') {
      this.setState({ hasSzamlazasiCim: false });
    }
  }

  deleteBankadat = (idx) => {
    let newState = this.state.bankAdatok;
    newState.splice(idx, 1);
    this.setState({ bankAdatok: newState });
  }

  addBankAdatRow = () => {
    let newBankadat = this.state.bankAdatok;
    newBankadat.push({
      bankszamlaszam: '',
      penzintezet: '',
      ibanszam: ''
    });
    this.setState({ bankAdatok: newBankadat });
  }

  addHozzajarulasAdatRow = () => {
    let newhozzajarulasadat = this.state.hozzajarulas;
    newhozzajarulasadat.push({
      jogalapid: -1,
      kezdete: new Date().toISOString().substring(0, 10),

    });
    this.setState({ hozzajarulas: newhozzajarulasadat });
  }

  navToList = () => {
    browserHistory.push('/torzsadatok/partnerek');
  }

  getKotelezosegek = (id) => {
    let kotelezoseg = [];
    if (this.state.isCeg && this.state.cegkotelezosegek.length !== 0) {
      this.state.cegkotelezosegek.map((item) => {
        if (item.id === parseInt(id, 10)) {
          kotelezoseg.push(item);
        }
      });
    }
    if (this.state.isMaganszemely && this.state.maganszemelykotelezosegek.length !== 0) {
      this.state.maganszemelykotelezosegek.map((item) => {
        if (item.id === parseInt(id, 10)) {
          kotelezoseg.push(item);
        }
      });
    }
    return kotelezoseg;
  }

  renderKiegeszitoAdatok = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      (!this.state.isLathato) && (
        <React.Fragment>
        <div className="row" hidden={this.state.isMaganszemely ? (this.getRequired('m_szulido') === null && this.getRequired('m_nem') === null && this.getRequired('m_nevnap') === null && this.getRequired('m_taj') === null && this.getRequired('m_megjegyzes') === null) : this.getRequired('c_megjegyzes') === null}>
        <div className="col-md-12">
          <div>
            <h5 style={titleStyle}>
              Kiegészítő adatok:
            </h5>
          </div>
        </div>
        {this.state.isMaganszemely ? (
          <React.Fragment>
           <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szulido') : false}>
              <Label for="szuletesiido" id="szulidolabel">
              {(this.state.isCeg ? this.getRequired('c_szulido') : this.getRequired('m_szulido')) ? "Születési idő: *" : "Születési idő: "}
              </Label>
              <DatePicker
                // parse={str => new Date(str)}
                max={new Date()}
                type="select"
                name="szuletesiido"
                id="szuletesiido"
                onChange={(e, v) => {this.handleSzulidoChange(e); this.validateCustomComponent(e, v, "szuletesiido", "szulidoerror", "szulidolabel", "m_szulido");}}
                // onClick={(e, v) => {this.validateCustomComponent(v, "szuletesiido", "szulidoerror", "szulidolabel", "m_szulido")}}
                // required={this.getRequired('m_szulido')}
                // onSelect={(e) => {this.validateDatePicker(e)}}
                // onToggle={(e) => {this.validateDatePicker(e)}}
                value={this.state.szuletesiido}
              />
            <div id="szulidoerror" style={{ color: 'red' }} hidden> Kötelező mező! </div>
            </div>
            <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_nem') : false}>
              <AvField
                type="select"
                name="neme"
                id="neme"
                label={(this.state.isCeg ? this.getRequired('c_nem') : this.getRequired('m_nem')) ? "Neme: *" : "Neme: "}
                required={this.getRequired('m_nem')}

                errorMessage="Kötelező mező!"
                value={this.state.modositoObj && this.state.modositoObj.neme && this.state.modositoObj.neme.name}
                // onChange={e => this.tipusChange(e)}
              >
                <option disabled value="">
                  Válassz nemet...
                </option>
                {this.state.nemOptions}
              </AvField>
            </div>
            <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_nevnap') : false}>
              <AvGroup>
                <Label for="nevnap">
                {(this.state.isCeg ? this.getRequired('c_nevnap') : this.getRequired('m_nevnap')) ? "Névnap: *" : "Névnap: "}
                </Label>
                <AvInput
                  name="nevnap"
                  id="nevnap"
                  max="9999"
                  required={this.getRequired('m_nevnap')}
                  value={this.state.modositoObj && this.state.modositoObj.nevnap}
                />
                <AvFeedback>
                  Max 4 számjegy!
                </AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_taj') : false}>
              <SlaveAvInput
                id="tajszam"
                label={(this.state.isCeg ? this.getRequired('c_taj') : this.getRequired('m_taj')) ? "Taj szám: *" : "Taj szám: "}
                maxLength="30"
                value={this.state.modositoObj && this.state.modositoObj.tajszam}
                required={this.getRequired('m_taj')}
                szuroTipus={NumberFormatter.TAJSZAM}
              />
            </div>
          </React.Fragment>
        ) : <span />}
      </div>
      <div className="row">
       <div className="col-md-12" hidden={this.state.isMaganszemely ? this.getHidden('m_megjegyzes') : false}>
       <AvGroup>
         <Label for="megjegyzes">
         {(this.state.isCeg ? this.getRequired('c_megjegyzes') : this.getRequired('m_megjegyzes')) ? "Megjegyzés: *" : "Megjegyzés: "}
         </Label>
         <AvInput className="munkalap-profil-textarea" type="textarea" name="megjegyzes" required={this.state.isCeg ? this.getRequired('c_megjegyzes') : this.getRequired('m_megjegyzes')} id="megjegyzes" maxLength="350" value={this.state.modositoObj && this.state.modositoObj.megjegyzes} />
         <AvFeedback>
           Kötelező mező!
         </AvFeedback>
       </AvGroup>
     </div>
     </div>
     </React.Fragment>
      )

    );
  }

  renderAlapAdatok = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      <div className="row">
        <div className="col-md-12">
          <div>
            <h5 style={titleStyle}>
              Alapadatok:
            </h5>
          </div>
        </div>
        <div className="col-md-6" hidden={this.state.isMaganszemely ? this.getHidden('m_tipus') : false}>
          <AvField
            type="select"
            name="tipus"
            id="tipus"
            label="Típus: *"
            errorMessage="Kötelező mező!"
            required={this.state.isCeg ? this.getRequired('c_tipus') : this.getRequired('m_tipus')}
            value={this.state.modositoObj && this.state.modositoObj.tipus && this.state.modositoObj.tipus.name}
            onChange={e => this.tipusChange(e)}
          >
            <option disabled value="">
              Válassz típust...
            </option>
            {this.state.partnerTipusOptions}
          </AvField>
        </div>
        <div className="col-md-6">
          <AvField
            type="select"
            name="partnercsoportentity"
            id="partnercsoportentity"
            label="Csoport: *"
            errorMessage="Kötelező mező!"
            value={(this.state.modositoObj && this.state.modositoObj.partnercsoportentity && this.state.modositoObj.partnercsoportentity.id) ? this.state.modositoObj.partnercsoportentity.id : this.state.partnercsoportOptions[0]}
            // defaultValue={this.state.partnercsoportOptions[0]}
            onChange={e => this.handlePartnerCsoportChange(e)}
            required
            disabled={!this.state.isMaganszemely && !this.state.isCeg}
          >
            {this.state.partnercsoportOptions}
          </AvField>
        </div>

        {(!this.state.isLathato) && (
          <React.Fragment>
            <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_azonosito') : false}>
              <AvGroup>
                <Label for="azonosito">
            Partner azonosító: *
                </Label>
                <AvInput
                  name="azonosito"
                  id="azonosito"
                  maxLength="30"
                  disabled
                  value={this.state.modositoObj && this.state.modositoObj.azonosito}
                />
                <AvFeedback>
            Kötelező mező!
                </AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_statusz') : false}>
              <AvField
                type="select"
                name="statusz"
                id="statusz"
                label="Státusz: *"
                errorMessage="Kötelező mező!"
                required={this.state.isCeg ? this.getRequired('c_statusz') : this.getRequired('m_statusz')}
                value={this.state.modositoObj && this.state.modositoObj.statusz}
              >
                {this.state.statuszOptions}
              </AvField>
            </div>
            <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_szallitovevo') : false}>
              <AvField
                type="select"
                name="szallitovevo"
                id="szallitovevo"
                label={(this.state.isCeg ? this.getRequired('c_szallitovevo') : this.getRequired('m_szallitovevo')) ? "Szállító/Vevő: *" : "Szállító/Vevő: "}
                errorMessage="Kötelező mező!"
                required={this.state.isCeg ? this.getRequired('c_szallitovevo') : this.getRequired('m_szallitovevo')}
                value={this.state.modositoObj && this.state.modositoObj.szallitovevo && this.state.modositoObj.szallitovevo.name}
                // onChange={e => this.tipusChange(e)}
              >
                <option disabled value="">
                  Válassz típust...
                </option>
                {this.state.szallitovevoOptions}
              </AvField>
            </div>
          </React.Fragment>
        )
        }
        {(this.state.isCeg && !this.state.isLathato)
          ? (
            <React.Fragment>
              <div className="col-md-4">
                <AvGroup>
                  <Label for="cegnev">
                    {this.getRequired('c_nev') ? "Cégnév: *" : "Cégnév: "}
                  </Label>
                  <AvInput
                    name="cegnev"
                    id="cegnev"
                    required={this.getRequired('c_nev')}
                    value={this.state.isMaganszemelyCegre ? ((this.state.modositoObj.titulus ? this.state.modositoObj.titulus : '') + ' ' + (this.state.modositoObj.vezeteknev && this.state.modositoObj.vezeteknev) + ' ' + (this.state.modositoObj.keresztnev && this.state.modositoObj.keresztnev)) : (this.state.modositoObj && this.state.modositoObj.cegnev)}
                  />
                  <AvFeedback>
                    Kötelező mező!
                  </AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-4">
                <AvGroup>
                  <Label for="cegjegyzekszam">
                    {this.getRequired('c_cegjegyzek') ? "Cégjegyzékszám: *" : "Cégjegyzékszám: "}
                  </Label>
                  <AvInput
                    name="cegjegyzekszam"
                    id="cegjegyzekszam"
                    maxLength="15"
                    required={this.getRequired('c_cegjegyzek')}
                    value={this.state.modositoObj && this.state.modositoObj.cegjegyzekszam}
                  />
                  <AvFeedback>
                    Kötelező mező!
                  </AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-4">
                <AvGroup>
                  <Label for="kapcsolattarto">
                    {this.getRequired('c_kapcstart') ? "Kapcsolattartó: *" : "Kapcsolattartó: "}
                  </Label>
                  <AvInput
                    name="kapcsolattarto"
                    id="kapcsolattarto"
                    maxLength="30"
                    required={this.getRequired('c_kapcstart')}
                    value={this.state.modositoObj && this.state.modositoObj.kapcsolattarto}
                  />
                  <AvFeedback>
                    Kötelező mező!
                  </AvFeedback>
                </AvGroup>
              </div>
            </React.Fragment>
          ) : <span />
          }
        {this.state.isMaganszemely && !this.state.isLathato
          ? (
            <React.Fragment>
              <div className="col-md-4">
                <AvGroup>
                  <Label for="titulus">
                    Titulus:
                  </Label>
                  <AvInput
                    name="titulus"
                    id="titulus"
                    maxLength="30"
                    hidden={this.state.isMaganszemely ? this.getHidden('m_nev') : false}
                    value={this.state.modositoObj && this.state.modositoObj.titulus}
                  />
                  <AvFeedback>
                    Kötelező mező!
                  </AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-4">
                <AvGroup>
                  <Label for="vezeteknev">
                    {this.getRequired('m_nev') ? "Vezetéknév: *" : "Vezetéknév: "}
                  </Label>
                  <AvInput
                    name="vezeteknev"
                    id="vezeteknev"
                    maxLength="30"
                    required={this.state.isMaganszemely ? this.getRequired('m_nev') : false}
                    value={this.state.modositoObj && (this.state.isCegMaganszemelyre ? this.state.modositoObj.cegnev : this.state.modositoObj.vezeteknev)}
                  />
                  <AvFeedback>
                    Kötelező mező!
                  </AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_nev') : false}>
                <AvGroup>
                  <Label for="keresztnev">
                    {this.getRequired('m_nev') ? "Keresztnév: *" : "Keresztnév: "}
                  </Label>
                  <AvInput
                    name="keresztnev"
                    id="keresztnev"
                    maxLength="30"
                    required={this.state.isMaganszemely ? this.getRequired('m_nev') : false}
                    value={this.state.modositoObj && this.state.modositoObj.keresztnev}
                  />
                  <AvFeedback>
                    Kötelező mező!
                  </AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-6"  hidden={this.state.isMaganszemely ? this.getHidden('m_szulnev') : false}>
                <AvGroup>
                  <Label for="szulvezeteknev">
                    {this.getRequired('m_szulnev') ? "Születési vezetéknév: *" : "Születési vezetéknév: "}
                  </Label>
                  <AvInput
                    name="szulvezeteknev"
                    id="szulvezeteknev"
                    maxLength="30"
                    required={this.getRequired('m_szulnev')}
                    value={this.state.modositoObj && this.state.modositoObj.szulvezeteknev}
                  />
                  <AvFeedback>
                    Kötelező mező!
                  </AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-6"  hidden={this.state.isMaganszemely ? this.getHidden('m_szulnev') : false}>
                <AvGroup>
                  <Label for="szulkeresztnev">
                    {this.getRequired('m_szulnev') ? "Születési keresztnév: *" : "Születési keresztnév: "}
                  </Label>
                  <AvInput
                    name="szulkeresztnev"
                    id="szulkeresztnev"
                    maxLength="30"
                    required={this.getRequired('m_szulnev')}
                    hidden={this.state.isMaganszemely ? this.getHidden('m_szulnev') : false}
                    value={this.state.modositoObj && this.state.modositoObj.szulkeresztnev}
                  />
                  <AvFeedback>
                    Kötelező mező!
                  </AvFeedback>
                </AvGroup>
              </div>
            </React.Fragment>
          ) : <span />
          }
      </div>
    );
  }

  /** Dropdown input mező értékek renderelése. */
  renderOptions = () => {
    return this.state.telepulesMezo.map(e => (
      <option
        key={e.telepulesid}
        value={e.telepulesid}
      >
        {e.telepulesnev}
      </option>
    ));
  }

  /** Dropdown input mező értékek renderelése. */
  renderSzallOptions = () => {
    return this.state.telepulesSzallMezo.map(e => (
      <option
        key={e.telepulesid}
        value={e.telepulesid}
      >
        {e.telepulesnev}
      </option>
    ));
  }

  /** Dropdown input mező értékek renderelése. */
  renderSzamOptions = () => {
    return this.state.telepulesSzamMezo.map(e => (
      <option
        key={e.telepulesid}
        value={e.telepulesid}
      >
        {e.telepulesnev}
      </option>
    ));
  }

  renderHivatalosCim = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      (!this.state.isLathato) && (
        <div className="row" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
          <div className="col-md-12">
            <div>
              <h5 style={titleStyle}>
                {'Hivatalos cím:'}{(this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) ? ' *' : ''}
              </h5>
            </div>
          </div>
          <div className="col-md-4">
            <Label for="form-field-name">
              {/* {(this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) ? "Ország: *" : "Ország: "} */}
              Ország: *
            </Label>
            {/* <Select.Async
            name="form-field-name"
            id="form-field-name"
            placeholder="Kezdjen el gépelni a kereséshez..."
            noResultsText="Nincs keresési eredmény"
            clearable
            ignoreAccents={false}
            loadOptions={_.debounce((input, callback) => this.loadOrszagOptions(input, callback), 1000)}
            isLoading={false}
            valueKey="orszagid"
            labelKey="nev"
            onChange={v => this.handleChangeOrszag(v)}
            value={this.state.orszagValue}
            cache={false}
            autoload={false}
            searchPromptText="Gépeljen a kereséshez..."
            loadingPlaceholder="Töltés..."
          /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="form-field-name"
              id="form-field-name"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeOrszag(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadOrszagOptions, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.orszagid}
              value={this.state.orszagValue}
              // required={this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')}
            // disabled={this.state.fizTeljElerheto}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-4"  hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              <Label for="iranyitoszam">
                {/* {(this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) ? "Irányítószám: *" : "Irányítószám: "} */}
                Irányítószám: *
              </Label>
              {this.state.telepulesSelect
                ? (
                  <div>
                    <AvInput
                      name="iranyitoszam"
                      id="iranyitoszam"
                      ref="iranyitoszam"
                      onChange={this.handleIrszamChange}
                      minLength="4"
                      maxLength="20"
                      pattern="^[0-9]*$"
                      // required={this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')}
                      value={this.state.modositoObj && this.state.modositoObj.iranyitoszam}
                    />
                    <AvFeedback>
                    Kötelező 4 számjegyű mező!
                    </AvFeedback>
                  </div>
                ) : (
                  <div hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
                    <AvInput
                      name="iranyitoszam"
                      id="iranyitoszam"
                      maxLength="20"
                      // required={this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')}
                      value={this.state.modositoObj && this.state.modositoObj.iranyitoszam}
                    />
                    <AvFeedback>
                    Kötelező mező!
                    </AvFeedback>
                  </div>
                )}
            </AvGroup>
          </div>
          <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              {this.state.telepulesSelect ? (
                <div>
                  <AvField
                    type="select"
                    name="telepulesid"
                    id="telepulesid"
                    // label={(this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) ? "Település: *" : "Település: "}
                    label="Település: *"
                    disabled={this.state.telepulesMezoInaktiv}
                    value={this.state.telepulesid}
                    errorMessage="Kötelező mező!"
                    onChange={this.handleTelepulesChange}
                    // required={this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')}
                  >
                    <option value="">
                      Válassz települést...
                    </option>
                    {this.renderOptions()}
                  </AvField>
                </div>
              ) : (
                <div hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
                  <Label for="telepulesnev">
                 {/* {(this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) ? "Település: *" : "Település: "} */}
                 Település: *
                  </Label>
                  <AvInput
                    name="telepulesnev"
                    id="telepulesnev"
                    // required={this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')}
                    value={this.state.modositoObj && this.state.modositoObj.telepulesnev}
                  />
                  <AvFeedback>
                  Kötelező mező!
                  </AvFeedback>
                </div>
              )}
            </AvGroup>
          </div>
          <div className="col-md-8" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              <Label for="kozterulet">
              {/* {(this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) ? "Közterület neve: *" : "Közterület neve: "} */}
              Közterület neve:
              </Label>
              <AvInput
                name="kozterulet"
                id="kozterulet"
                // required={this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')}
                maxLength="30"
                value={this.state.modositoObj && this.state.modositoObj.kozterulet}
              />
              <AvFeedback>
              Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              <Label for="kozteruletjelleg">
              {/* {(this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) ? "Közterület jellege: *" : "Közterület jellege: "} */}
              Közterület jellege:
              </Label>
              <AvInput
                name="kozteruletjelleg"
                id="kozteruletjelleg"
                // required={this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')}
                maxLength="20"
                value={this.state.modositoObj && this.state.modositoObj.kozteruletjelleg}
              />
              <AvFeedback>
              Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              <Label for="hazszam">
              {/* {(this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) ? "Házszám: *" : "Házszám: "} */}
              Házszám:
              </Label>
              <AvInput
                name="hazszam"
                id="hazszam"
                maxLength="30"
                // required={!this.state.hasHrsz ? (this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) : false }
                onChange={(e) => {this.handleHszamChange(e);}}
                value={this.state.modositoObj && this.state.modositoObj.hazszam}
              />
            </AvGroup>
          </div>
          <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              <Label for="hrsz">
              {/* {(this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) ? "Hrsz: *" : "Hrsz: "} */}
              Hrsz:
              </Label>
              <AvInput
                name="hrsz"
                id="hrsz"
                maxLength="30"
                // required={!this.state.hasHazszam ? (this.state.isCeg ? this.getRequired('c_cim') : this.getRequired('m_cim')) : false}
                onChange={(e) => {this.handleHrszChange(e);}}
                value={this.state.modositoObj && this.state.modositoObj.hrsz}
              />
            </AvGroup>
          </div>
          <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              <Label for="lepcsohaz">
              Lépcsőház:
              </Label>
              <AvInput
                name="lepcsohaz"
                id="lepcsohaz"
                maxLength="4"
                value={this.state.modositoObj && this.state.modositoObj.lepcsohaz}
              />
              <AvFeedback>
              Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              <Label for="epulet">
              Épület:
              </Label>
              <AvInput
                name="epulet"
                id="epulet"
                maxLength="4"
                value={this.state.modositoObj && this.state.modositoObj.epulet}
              />
              <AvFeedback>
              Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              <Label for="emelet">
              Emelet:
              </Label>
              <AvInput
                name="emelet"
                id="emelet"
                maxLength="4"
                value={this.state.modositoObj && this.state.modositoObj.emelet}
              />
              <AvFeedback>
              Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              <Label for="ajto">
              Ajtó:
              </Label>
              <AvInput
                name="ajto"
                id="ajto"
                maxLength="15"
                value={this.state.modositoObj && this.state.modositoObj.ajto}
              />
              <AvFeedback>
              Max 4 számjegy!
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_cim') : false}>
            <AvGroup>
              <Label for="postafiok">
              Postafiók:
              </Label>
              <AvInput
                name="postafiok"
                id="postafiok"
                maxLength="30"
                value={this.state.modositoObj && this.state.modositoObj.postafiok}
              />
              <AvFeedback>
              Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
       </div>
      )
    );
  }

  renderTelefonszamMezok = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      !this.state.isLathato && (
        <div className="row">
        <div className="col-md-12" hidden={this.state.isMaganszemely ? this.getHidden('m_tel') : false}>
        <div>
          <h5 style={titleStyle}>
          Telefonszámok:
          </h5>
        </div>
        </div>
        {this.renderTelefonszamok()}
        <div className="col-md-12" hidden={this.state.isMaganszemely ? this.getHidden('m_tel') : false}>
        <Button
          className="pull-left table-new-button"
          color="success"
          onClick={() => this.handleNewPhoneClick()}
        >
        Telefonszám hozzáadása
        </Button>
        </div>
      </div>
      )
    );
  }

  renderSzallitasiMezok = () => {
    return (
      <React.Fragment>
        <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <Label for="szallorszagnev">
            {/* {(this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) ? "Ország: *" : "Ország: "} */}
            Ország: *
          </Label>
          {/* <Select.Async
            name="szallorszagnev"
            id="szallorszagnev"
            placeholder="Kezdjen el gépelni a kereséshez..."
            noResultsText="Nincs keresési eredmény"
            clearable
            ignoreAccents={false}
            loadOptions={_.debounce((input, callback) => this.loadOrszagOptions(input, callback), 1000)}
            isLoading={false}
            valueKey="orszagid"
            labelKey="nev"
            onChange={v => this.handleChangeSzallOrszag(v)}
            value={this.state.orszagSzallValue}
            cache={false}
            autoload={false}
            searchPromptText="Gépeljen a kereséshez..."
            loadingPlaceholder="Töltés..."
          /> */}
          <Select
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="szallorszagnev"
            id="szallorszagnev"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingMessage={() => 'Keresés...'}
            onChange={v => this.handleChangeSzallOrszag(v)}
            isClearable
            ignoreAccents={false}
            loadOptions={_.debounce(this.loadOrszagOptions, 1000)}
            isLoading={false}
            getOptionLabel={option => option.nev}
            getOptionValue={option => option.orszagid}
            value={this.state.orszagSzallValue}
            // required={this.state.hasSzallitasiCim ? (this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) : false}
            cache={false}
            autoload={false}
          />
        </div>
        <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            <Label for="szalliranyitoszam">
              {/* {(this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) ? "Irányítószám: *" : "Irányítószám: "} */}
              Irányítószám: *
            </Label>
            {this.state.telepulesSzallSelect
              ? (
                <div>
                  <AvInput
                    name="szalliranyitoszam"
                    id="szalliranyitoszam"
                    ref="iranyitoszamSzall"
                    onChange={this.handleIrszamSzallChange}
                    minLength="4"
                    maxLength="20"
                    pattern="^[0-9]*$"
                    // required={this.state.hasSzallitasiCim ? (this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) : false}
                    value={this.state.modositoObj && this.state.modositoObj.szalliranyitoszam}
                  />
                  <AvFeedback>Kötelező 4 számjegyű mező!</AvFeedback>
                </div>
              ) : (
                <div>
                  <AvInput
                    name="szalliranyitoszam"
                    id="szalliranyitoszam"
                    maxLength="20"
                    // required={this.state.hasSzallitasiCim ? (this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) : false}
                    value={this.state.modositoObj && this.state.modositoObj.szalliranyitoszam}
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </div>
              )}
          </AvGroup>
        </div>
        <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            {this.state.telepulesSzallSelect ? (
              <div>
                <AvField
                  type="select"
                  name="szalltelepulesid"
                  id="szalltelepulesid"
                  // label={(this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) ? "Település: *" : "Település: "}
                  label="Település: *"
                  disabled={this.state.telepulesSzallMezoInaktiv}
                  value={this.state.szalltelepulesid}
                  errorMessage="Kötelező mező!"
                  onChange={this.handleSzallTelepulesChange}
                  // required={this.state.hasSzallitasiCim ? (this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) : false}
                >
                  <option value="">Válassz települést...</option>
                  {this.renderSzallOptions()}
                </AvField>
              </div>
            ) : (
              <div>
                {/* <Label for="szalltelepulesnev">Település: {(this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) ? "*" : ""}</Label> */}
                <Label for="szalltelepulesnev">Település: *</Label>
                <AvInput
                  name="szalltelepulesnev"
                  id="szalltelepulesnev"
                  // required={this.state.hasSzallitasiCim ? (this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) : false}
                  value={this.state.modositoObj && this.state.modositoObj.szalltelepulesnev}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </div>
            )}
          </AvGroup>
        </div>
        <div className="col-md-8" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            {/* <Label for="szallkozterulet">{(this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) ? "Közterület neve: *" : "Közterület neve: "} </Label> */}
            <Label for="szallkozterulet">Közterület neve:</Label>
            <AvInput
              name="szallkozterulet"
              id="szallkozterulet"
              // required={this.state.hasSzallitasiCim ? (this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) : false}
              maxLength="30"
              value={this.state.modositoObj && this.state.modositoObj.szallkozterulet}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            {/* <Label for="szallkozteruletjelleg">{(this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) ? "Közterület jellege: *" : "Közterület jellege: "} </Label> */}
            <Label for="szallkozteruletjelleg">Közterület jellege:</Label>
            <AvInput
              name="szallkozteruletjelleg"
              id="szallkozteruletjelleg"
              // required={this.state.hasSzallitasiCim ? (this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) : false}
              maxLength="20"
              value={this.state.modositoObj && this.state.modositoObj.szallkozteruletjelleg}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            {/* <Label for="szallhazszam">{(this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) ? "Házszám: *" : "Házszám: "} </Label> */}
            <Label for="szallhazszam">Házszám:</Label>
            <AvInput
              name="szallhazszam"
              id="szallhazszam"
              // required={!this.state.hasSzallHrsz ? (this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) : false}
              maxLength="30"
              onChange={(e) => {this.handleSzallHszamChange(e);}}
              value={this.state.modositoObj && this.state.modositoObj.szallhazszam}
            />
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            {/* <Label for="szallhrsz">{(this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) ? "Hrsz: *" : "Hrsz: "} </Label> */}
            <Label for="szallhrsz">Hrsz:</Label>
            <AvInput
              name="szallhrsz"
              id="szallhrsz"
              maxLength="30"
              onChange={(e) => {this.handleSzallHrszChange(e);}}
              // required={!this.state.hasSzallHazszam ? (this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) : false}
              value={this.state.modositoObj && this.state.modositoObj.szallhrsz}
            />
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            <Label for="szalllepcsohaz">Lépcsőház: </Label>
            <AvInput
              name="szalllepcsohaz"
              id="szalllepcsohaz"
              maxLength="4"
              value={this.state.modositoObj && this.state.modositoObj.szalllepcsohaz}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            <Label for="szallepulet">Épület: </Label>
            <AvInput
              name="szallepulet"
              id="szallepulet"
              maxLength="4"
              value={this.state.modositoObj && this.state.modositoObj.szallepulet}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            <Label for="szallemelet">Emelet: </Label>
            <AvInput
              name="szallemelet"
              id="szallemelet"
              maxLength="4"
              value={this.state.modositoObj && this.state.modositoObj.szallemelet}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            <Label for="szallajto">Ajtó: </Label>
            <AvInput
              name="szallajto"
              id="szallajto"
              maxLength="15"
              value={this.state.modositoObj && this.state.modositoObj.szallajto}
            />
            <AvFeedback>Max 4 számjegy</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szallcim') : false}>
          <AvGroup>
            <Label for="szallpostafiok">Postafiók: </Label>
            <AvInput
              name="szallpostafiok"
              id="szallpostafiok"
              maxLength="30"
              value={this.state.modositoObj && this.state.modositoObj.szallpostafiok}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
      </React.Fragment>
    );
  }

  renderSzallitasiCimek = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      (!this.state.isLathato && (this.state.isMaganszemely ? (this.getRequired('m_szallcim') !== null) : this.getRequired('c_szallcim') !== null)) && (
        <div className="row">
        <div className="col-md-12">
          <h5 style={titleStyle}>
          {'Szállítási cím:'}{(this.state.isCeg ? this.getRequired('c_szallcim') : this.getRequired('m_szallcim')) ? ' *' : ''}
          </h5>
        </div>
        <div className="col-md-3">
          <AvField
            type="select"
            name="szallcimeltero"
            id="szallcimeltero"
            errorMessage="Kötelező mező!"
            required
            disabled={this.state.isMaganszemely ? this.getRequired('m_szallcim') : this.getRequired('c_szallcim') }
            label="Szállítási cím megadása: *"
            // defaultValue="false"
            value={this.state.modositoObj && this.state.modositoObj.szallcimeltero ? this.state.modositoObj.szallcimeltero.toString() : ((this.getRequired('m_szallcim') === true || this.getRequired('c_szallcim') === true) ? 'true' : 'false')}
            onChange={e => this.szallcimelteroChange(e)}
          >
            <option value="false">
            Nem
            </option>
            <option value="true">
            Igen
            </option>
          </AvField>
        </div>
        {((this.state.hasSzallitasiCim || (this.state.modositoObj && this.state.modositoObj.szallcimeltero && this.state.modositoObj.szallcimeltero.toString() === 'true')) && (this.state.isMaganszemly ? this.getRequired('m_szallcim') !== null : this.getRequired('c_szallcim') !== null)) && (
          <React.Fragment>
            <div className="col-md-12" />
            {this.renderSzallitasiMezok()}
          </React.Fragment>
        )
        }
    </div>
    )
    );
  }


  renderSzamlazasiMezok = () => {
    return (
      <React.Fragment>
        <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <Label for="szamorszagnev">
            {/* {(this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) ? "Ország: *" : "Ország: "} */}
            Ország: *
          </Label>
          {/* <Select.Async
            name="szamorszagnev"
            id="szamorszagnev"
            placeholder="Kezdjen el gépelni a kereséshez..."
            noResultsText="Nincs keresési eredmény"
            clearable
            ignoreAccents={false}
            loadOptions={_.debounce((input, callback) => this.loadOrszagOptions(input, callback), 1000)}
            isLoading={false}
            valueKey="orszagid"
            labelKey="nev"
            onChange={v => this.handleChangeSzamOrszag(v)}
            value={this.state.orszagSzamValue}
            cache={false}
            autoload={false}
            searchPromptText="Gépeljen a kereséshez..."
            loadingPlaceholder="Töltés..."
          /> */}
          <Select
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="szamorszagnev"
            id="szamorszagnev"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingMessage={() => 'Keresés...'}
            onChange={v => this.handleChangeSzamOrszag(v)}
            isClearable
            // required={this.state.hasSzamlazasiCim ? (this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) : false}
            ignoreAccents={false}
            loadOptions={_.debounce(this.loadOrszagOptions, 1000)}
            isLoading={false}
            getOptionLabel={option => option.nev}
            getOptionValue={option => option.orszagid}
            value={this.state.orszagSzamValue}
            // disabled={this.state.fizTeljElerheto}
            cache={false}
            autoload={false}
          />
        </div>
        <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            <Label for="szamiranyitoszam">
              {/* {(this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) ? "Irányítószám: *" : "Irányítószám: "} */}
              Irányítószám: *
            </Label>
            {this.state.telepulesSzamSelect
              ? (
                <div>
                  <AvInput
                    name="szamiranyitoszam"
                    id="szamiranyitoszam"
                    ref="iranyitoszamSzam"
                    onChange={this.handleIrszamSzamChange}
                    value={this.state.modositoObj && this.state.modositoObj.szamiranyitoszam}
                    minLength="4"
                    maxLength="20"
                    pattern="^[0-9]*$"
                    // required={this.state.hasSzamlazasiCim ? (this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) : false}
                  />
                  <AvFeedback>Kötelező 4 számjegyű mező!</AvFeedback>
                </div>
              )
              : (
                <div>
                  <AvInput
                    name="szamiranyitoszam"
                    id="szamiranyitoszam"
                    maxLength="20"
                    // required={this.state.hasSzamlazasiCim ? (this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) : false}
                    value={this.state.modositoObj && this.state.modositoObj.szamiranyitoszam}
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </div>
              ) }
          </AvGroup>
        </div>
        <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            {this.state.telepulesSzamSelect ? (
              <div>
                <AvField
                  type="select"
                  name="szamtelepulesid"
                  id="szamtelepulesid"
                  // label={(this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) ? "Település: *" : "Település: "}
                  label="Település: *"
                  disabled={this.state.telepulesSzamMezoInaktiv}
                  value={this.state.szamtelepulesid}
                  errorMessage="Kötelező mező!"
                  onChange={this.handleSzamTelepulesChange}
                  // required={this.state.hasSzamlazasiCim ? (this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) : false}
                >
                  <option value="">
                    Válassz települést...
                  </option>
                  {this.renderSzamOptions()}
                </AvField>
              </div>
            ) : (
              <div>
                <Label for="szamtelepulesnev">
                  {/* {(this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) ? "Település: *" : "Település: "} */}
                  Település: *
                </Label>
                <AvInput
                  name="szamtelepulesnev"
                  id="szamtelepulesnev"
                  value={this.state.modositoObj && this.state.modositoObj.szamtelepulesnev}
                  // required={this.state.hasSzamlazasiCim ? (this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) : false}
                />
                <AvFeedback>
                  Kötelező mező!
                </AvFeedback>
              </div>
            )}
          </AvGroup>
        </div>
        <div className="col-md-8" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            <Label for="szamkozterulet">
              {/* {(this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) ? "Közterület neve: *" : "Közterület neve: "} */}
              Közterület neve:
            </Label>
            <AvInput
              name="szamkozterulet"
              id="szamkozterulet"
              // required={this.state.hasSzamlazasiCim ? (this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) : false}
              maxLength="30"
              value={this.state.modositoObj && this.state.modositoObj.szamkozterulet}
            />
            <AvFeedback>
              Kötelező mező!
            </AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            {/* <Label for="szamkozteruletjelleg">{(this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) ? "Közterület jellege: *" : "Közterület jellege: "} </Label> */}
            <Label for="szamkozteruletjelleg">Közterület jellege:</Label>
            <AvInput
              name="szamkozteruletjelleg"
              id="szamkozteruletjelleg"
              // required={this.state.hasSzamlazasiCim ? (this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) : false}
              maxLength="20"
              value={this.state.modositoObj && this.state.modositoObj.szamkozteruletjelleg}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            {/* <Label for="szamhazszam">{(this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) ? "Házszám: *" : "Házszám: "} </Label> */}
            <Label for="szamhazszam">Házszám:</Label>
            <AvInput
              name="szamhazszam"
              id="szamhazszam"
              maxLength="30"
              // required={!this.state.hasSzamHrsz ? (this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) : false}
              onChange={(e) => {this.handleSzamHszamChange(e);}}
              value={this.state.modositoObj && this.state.modositoObj.szamhazszam}
            />
          </AvGroup>
        </div>

        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            {/* <Label for="szamhrsz">{(this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) ? "Hrsz: *" : "Hrsz: "} </Label> */}
            <Label for="szamhrsz">Hrsz:</Label>
            <AvInput
              name="szamhrsz"
              id="szamhrsz"
              maxLength="30"
              onChange={(e) => {this.handleSzamHrszChange(e);}}
              // required={!this.state.hasSzamHazszam ? (this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) : false}
              value={this.state.modositoObj && this.state.modositoObj.szamhrsz}
            />
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            <Label for="szamlepcsohaz">Lépcsőház: </Label>
            <AvInput
              name="szamlepcsohaz"
              id="szamlepcsohaz"
              maxLength="4"
              value={this.state.modositoObj && this.state.modositoObj.szamlepcsohaz}
            />
            <AvFeedback>
              Kötelező mező!
            </AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            <Label for="szamepulet">Épület: </Label>
            <AvInput
              name="szamepulet"
              id="szamepulet"
              maxLength="4"
              value={this.state.modositoObj && this.state.modositoObj.szamepulet}
            />
            <AvFeedback>
              Kötelező mező!
            </AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            <Label for="szamemelet">Emelet: </Label>
            <AvInput
              name="szamemelet"
              id="szamemelet"
              maxLength="4"
              value={this.state.modositoObj && this.state.modositoObj.szamemelet}
            />
            <AvFeedback>
              Kötelező mező!
            </AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            <Label for="szamajto">Ajtó: </Label>
            <AvInput
              name="szamajto"
              id="szamajto"
              maxLength="15"
              value={this.state.modositoObj && this.state.modositoObj.szamajto}
            />
            <AvFeedback>
              Max 4 számjegy!
            </AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_szamcim') : false}>
          <AvGroup>
            <Label for="szampostafiok">Postafiók: </Label>
            <AvInput
              name="szampostafiok"
              id="szampostafiok"
              maxLength="30"
              value={this.state.modositoObj && this.state.modositoObj.szampostafiok}
            />
            <AvFeedback>
              Kötelező mező!
            </AvFeedback>
          </AvGroup>
        </div>
      </React.Fragment>
    );
  }

  renderSzamlazasiCimek = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      (!this.state.isLathato && (this.state.isMaganszemely ? (this.getRequired('m_szamcim') !== null) : this.getRequired('c_szamcim') !== null)) && (
        <div className="row">
        <div className="col-md-12">
          <h5 style={titleStyle}>
          {'Levelezési cím:'}{(this.state.isCeg ? this.getRequired('c_szamcim') : this.getRequired('m_szamcim')) ? ' *' : ''}
          </h5>
        </div>
        <div className="col-md-3">
          <AvField
            type="select"
            name="szamcimeltero"
            id="szamcimeltero"
            errorMessage="Kötelező mező!"
            required
            disabled={this.state.isMaganszemely ? this.getRequired('m_szamcim') : this.getRequired('c_szamcim')}
            label="Levelezési cím megadása: *"
            // defaultValue="false"
            value={this.state.modositoObj && this.state.modositoObj.szamcimeltero ? this.state.modositoObj.szamcimeltero.toString() : ((this.getRequired('m_szamcim') === true || this.getRequired('c_szamcim') === true) ? 'true' : 'false')}
            onChange={e => this.szamlcimelteroChange(e)}
          >
            <option value="false">
            Nem
            </option>
            <option value="true">
            Igen
            </option>
          </AvField>
        </div>
        {((this.state.hasSzamlazasiCim || (this.state.modositoObj && this.state.modositoObj.szamcimeltero && this.state.modositoObj.szamcimeltero.toString() === 'true')) && (this.state.isMaganszemly ? this.getRequired('m_szamcim') !== null : this.getRequired('c_szamcim') !== null)) && (
          <React.Fragment>
            <div className="col-md-12" />
            {this.state.hasSzamlazasiCim ? this.renderSzamlazasiMezok() : <span />}
          </React.Fragment>
        )}
      </div>
      )
    );
  }

  renderEgyebAdatok = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      (!this.state.isLathato) && (
        <div className="row" hidden={this.state.isMaganszemely ? (this.getRequired('m_email') === null && this.getRequired('m_kedvezmeny') === null && this.getRequired('m_adoazon') === null && this.getRequired('m_adoszam') === null && this.getRequired('m_kozadoszam') === null) : ((this.getRequired('c_email') === null && this.getRequired('c_kedvezmeny') === null && this.getRequired('c_adoazon') === null && this.getRequired('c_adoszam') === null && this.getRequired('c_kozadoszam') === null))}>
        <div className="col-md-12">
          <div>
            <h5 style={titleStyle}>
            Egyéb adatok:
            </h5>
          </div>
        </div>
        <div className="col-md-3" hidden={this.state.isMaganszemely ? this.getHidden('m_email') : false}>
          <AvGroup>
            <Label for="email">
              {(this.state.isCeg ? this.getRequired('c_email') : this.getRequired('m_email')) ? "Email: *" : "Email: "}
            </Label>
            <AvInput
              name="email"
              id="email"
              maxLength="100"
              required={this.state.isCeg ? this.getRequired('c_email') : this.getRequired('m_email')}
              value={this.state.modositoObj && this.state.modositoObj.email}
            />
            <AvFeedback>
              Kötelező mező!
            </AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-2" hidden={this.state.isMaganszemely ? this.getHidden('m_kedvezmeny') : false}>
          <AvGroup>
            <Label for="kedvezmeny">
              {(this.state.isCeg ? this.getRequired('c_kedvezmeny') : this.getRequired('m_kedvezmeny')) ? "Kedvezmény: (%) *" : "Kedvezmény: (%) "}
            </Label>
            <AvInput
              name="kedvezmeny"
              id="kedvezmeny"
              max="100"
              required={this.state.isCeg ? this.getRequired('c_kedvezmeny') : this.getRequired('m_kedvezmeny')}
              value={this.state.modositoObj && this.state.modositoObj.kedvezmeny}
            />
            <AvFeedback>
              Max 2 számjegy!
            </AvFeedback>
          </AvGroup>
        </div>
        {this.state.isMaganszemely ? (
          <div className="col-md-7"  hidden={this.state.isMaganszemely ? this.getHidden('m_adoazon') : false}>
            <SlaveAvInput
              id="adoazonosito"
              label={(this.state.isCeg ? this.getRequired('c_adoazon') : this.getRequired('m_adoazon')) ? "Adóazonosító jel: *" : "Adóazonosító jel: "}
              required={this.getRequired('m_adoazon')}
              value={this.state.modositoObj && this.state.modositoObj.adoazonosito}
              szuroTipus={NumberFormatter.ADOSZAMAZON}
            />
          </div>
        ) : <span />
        }
        <div className="col-md-4"  hidden={this.state.isMaganszemely ? this.getHidden('m_adoszam') : false}>
          <SlaveAvInput
            id="adoszam"
            label={(this.state.isCeg ? this.getRequired('c_adoszam') : this.getRequired('m_adoszam')) ? "Adószám: *" : "Adószám: "}
            maxLength="30"
            required={this.state.isCeg ? (this.getRequired('c_adoszam')) : (this.getRequired('m_adoszam'))}
            value={this.state.modositoObj && this.state.modositoObj.adoszam}
            szuroTipus={NumberFormatter.ADOSZAM}
          />
        </div>
        <div className="col-md-3"  hidden={this.state.isMaganszemely ? this.getHidden('m_kozadoszam') : false}>
          <AvGroup>
            <Label for="kozossegiadoszam">
              {(this.state.isCeg ? this.getRequired('c_kozadoszam') : this.getRequired('m_kozadoszam')) ? "Közösségi adószám: *" : "Közösségi adószám: "}
            </Label>
            <AvInput
              className="input-adoszamkozosseg"
              name="kozossegiadoszam"
              id="kozossegiadoszam"
              maxLength="30"
              required={this.state.isCeg ? this.getRequired('c_kozadoszam') : this.getRequired('m_kozadoszam')}
              value={this.state.modositoObj && this.state.modositoObj.kozossegiadoszam}
            />
            <AvFeedback>
              Kötelező mező!
            </AvFeedback>
          </AvGroup>
        </div>
      </div>
      )
    );
  }

  renderBankAdatok = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      !this.state.isLathato && (!this.getHidden('m_bankszamla')) && (
        <div className="row">
        <div className="col-md-12">
          <div>
            <h5 style={titleStyle}>
              Bank adatok:
            </h5>
          </div>
        </div>
        {this.renderBankList()}
        <div className="col-md-12">
          <Button className="pull-left table-new-button" color="success" onClick={() => this.addBankAdatRow()}>
            Új hozzáadása
          </Button>
        </div>
      </div>
      )
    );
  }

  renderHozzajarulasAdatok = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    this.handleHozzajarulasDisplay();
    if (!this.state.isLathato) {
      if (!this.state.isCeg || (this.state.isCeg && this.state.hozzajarulas.length !== 0 && this.state.modositoStatus)) {
        return (
          <React.Fragment>
          <div className="row" id="hozzajar" hidden={false}>
            <div className="col-md-12">
              <div>
                <h5 style={titleStyle}>
                Hozzájárulás adatok:
                </h5>
              </div>
            </div>
            {this.renderHozzajarulasList()}

            <div className="col-md-12" id="hozzajargomb" hidden={false}>
              <Button className="pull-left table-new-button" color="success" onClick={() => this.addHozzajarulasAdatRow()}>
              Új hozzájárulás
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {this.renderFileUpload()}
            </div>
          </div>
          </React.Fragment>
        );

      }
   return (
    <div className="row">
    <div className="col-md-12">
      {this.renderFileUpload()}
    </div>
    </div>
   );

  }}

  handlePenzintezedChange = (e, idx) => {
    let newState = this.state.bankAdatok;
    newState[idx].penzintezet = e.target.value;
    this.setState({ bankAdatok: newState });
  }

  handleBankszamlaszamchange = (e, idx) => {
    let newState = this.state.bankAdatok;
    newState[idx].bankszamlaszam = e.target.value;
    this.setState({ bankAdatok: newState });
  }

  handleIBANChange = (e, idx) => {
    let newState = this.state.bankAdatok;
    newState[idx].ibanszam = e.target.value;
    this.setState({ bankAdatok: newState });
  }

  handleTelefonTipusChange = (e, idx) => {
    let newState = this.state.telefonszamok;
    newState[idx].tipus = e.target.value;
    this.setState({ telefonszamok: newState });
  }

  handleTelefonszamChange = (e, idx) => {
    let newState = this.state.telefonszamok;
    newState[idx].szam = e.target.value;
    this.setState({ telefonszamok: newState });
  }

  handleElsodlegesChange = (e, idx) => {
    let isTrue = (e.target.value === 'true');
    let newState = this.state.telefonszamok;
    newState[idx].elsodleges = !isTrue;
    this.setState({ telefonszamok: newState });
  }

  deleteTelefonszam = (idx) => {
    let newState = this.state.telefonszamok;
    newState.splice(idx, 1);
    this.setState({
      telefonszamok: newState
    });
  }

  flagFormatter = (props, all) => {
    // let handleMouseDown = (event) => {
    //   this.setValue(props.num);
    // };

    return (
      <div className="flag-format" {...all}>
        <span
          className={'flag-icon flag-icon-' + props.name.toLowerCase()}
        />
        {' '}
        <b>{props.caption}</b>
        {' '}
+
        {props.num}
      </div>
    );
  }

  setValue = (value) => {
    this.state.orszagOptions.forEach((item) => {
      if (item.num === value.num && item.name === value.name) {
        this.setState({ formModalOrszaghivo: { num: item.num, name: item.name.toUpperCase(), caption: item.caption } });
      }
    });
  }

  renderTelefonszamok = () => {
    let telefonszamok = [];
    this.state.telefonszamok.forEach((item, idx) => {
      telefonszamok.push(
        <div className="col-md-4" key={idx.toString()}>
          <PhoneCard
            idx={idx.toString()}
            key={idx.toString()}
            szam={item.szam}
            orszag={item.orszag.caption}
            num={item.orszag.num ? '+' + item.orszag.num : ''}
            tipus={item.tipus.name}
            isElsodleges={item.elsodleges}
            onModifyClick={() => this.handlePhoneChangeClick(idx)}
            onDeleteClick={() => this.handlePhoneDelete(idx)}
            onElsodlegesClick={() => this.handlePhoneElsodlegesClick(idx)}
          />
        </div>
      );
    });
    return (
      telefonszamok
    );
  }

  handlePhoneElsodlegesClick = (idx) => {
    let newTelefonszamok = [];
    this.state.telefonszamok.forEach((item, itemidx) => {
      if (itemidx === idx) {
        item.elsodleges = true;
        newTelefonszamok.push(item);
      } else {
        item.elsodleges = false;
        newTelefonszamok.push(item);
      }
    });
    this.setState({
      telefonszamok: newTelefonszamok
    });
  }

  handlePhoneDelete = (index) => {
    this.setState(prevState => ({
      telefonszamok: [...prevState.telefonszamok.slice(0, index), ...prevState.telefonszamok.slice(index + 1)]
    }));
  }

  togglePhoneModal = () => {
    this.setState(prevState => ({ modalPhone: !prevState.modalPhone }));
  }

  addPhone = (event, phoneObj) => {
    this.setState({ phoneButtonLoading: true });

    let submitObj = {
      orszag: (this.state.formModalOrszaghivo === '' || this.state.formModalOrszaghivo === null) ? null : this.state.formModalOrszaghivo.name,
      tipus: this.state.formModalTipus,
      szam: this.state.formModalTelefonszam
    };

    Services.checkPhone(submitObj, (err) => {
      if (!err) {
        if (this.state.formPhoneModalType === 'FEL') {
          this.setState(prevState => ({
            phoneButtonLoading: false,
            telefonszamok: [...prevState.telefonszamok, {
              elsodleges: false,
              szam: prevState.formModalTelefonszam,
              tipus: { caption: prevState.formModalTipus, name: prevState.formModalTipus },
              orszag: prevState.formModalOrszaghivo
            }]
          }));
        } else {
          let prevTelefonszamok = JSON.parse(JSON.stringify(this.state.telefonszamok));
          prevTelefonszamok[this.state.currentPhoneId] = {
            elsodleges: prevTelefonszamok[this.state.currentPhoneId].elsodleges,
            szam: submitObj.szam,
            tipus: { caption: submitObj.tipus, name: submitObj.tipus },
            orszag: this.state.formModalOrszaghivo
          };
          this.setState({
            phoneButtonLoading: false,
            telefonszamok: prevTelefonszamok
          });
        }
        this.togglePhoneModal();
      } else {
        this.setState({ phoneButtonLoading: false });
      }
    });
  }

  handleNewPhoneClick = () => {
    this.setState({
      formModalOrszaghivo: '',
      formModalTelefonszam: '',
      formModalTipus: '',
      formPhoneModalType: 'FEL',
      modalPhoneTitle: 'Új telefonszám hozzáadása',
      modalPhoneButtonText: 'Hozzáadás'
    }, () => {
      this.togglePhoneModal();
    });
  }

  handlePhoneChangeClick = (idx) => {
    this.setState(prevState => ({
      formModalOrszaghivo: prevState.telefonszamok[idx].orszag,
      formModalTelefonszam: prevState.telefonszamok[idx].szam,
      formModalTipus: prevState.telefonszamok[idx].tipus.name,
      modalPhoneTitle: 'Telefonszám módosítása',
      currentPhoneId: idx,
      formPhoneModalType: 'MOD',
      modalPhoneButtonText: 'Módosítás'
    }), () => {
      this.togglePhoneModal();
    });
  }

  renderBankList = () => {
    let adatok = [];
    if (this.state.bankAdatok) {
      this.state.bankAdatok.forEach((item, idx) => {
        adatok.push(
          <React.Fragment key={idx.toString()}>
            <div className="col-md-3">
              <AvGroup>
                <Label for={'penzintezet' + idx}>
                  {(this.state.isCeg ? this.getRequired('c_bankszamla') : this.getRequired('m_bankszamla')) ? "Számlafizető pénzintézet neve: *" : "Számlafizető pénzintézet neve: "}
                </Label>
                <AvInput
                  name={'penzintezet' + idx}
                  id={'penzintezet' + idx}
                  required={this.state.isCeg ? this.getRequired('c_bankszamla') : this.getRequired('m_bankszamla')}
                  hidden={this.state.isMaganszemely ? this.getHidden('m_bankszamla') : false}
                  onBlur={e => this.handlePenzintezedChange(e, idx)}
                  value={this.state.modositoObj && this.state.bankAdatok[idx].penzintezet}
                />
                <AvFeedback>
                  Kötelező mező!
                </AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-3">
              <AvGroup>
                <Label for={'szamlaszam' + idx}>
                  {(this.state.isCeg ? this.getRequired('c_bankszamla') : this.getRequired('m_bankszamla')) ? "Bankszámlaszám: *" : "Bankszámlaszám: "}
                </Label>
                <AvInput
                  name={'szamlaszam' + idx}
                  id={'szamlaszam' + idx}
                  onBlur={e => this.handleBankszamlaszamchange(e, idx)}
                  required={this.state.isCeg ? this.getRequired('c_bankszamla') : this.getRequired('m_bankszamla')}
                  hidden={this.state.isMaganszemely ? this.getHidden('m_bankszamla') : false}
                  value={this.state.modositoObj && this.state.bankAdatok[idx].bankszamlaszam}
                />
                <AvFeedback>
                  Kötelező mező!
                </AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-3">
              <AvGroup>
                <Label for={'IBAN' + idx}>
                  IBAN szám:
                </Label>
                <AvInput
                  name={'IBAN' + idx}
                  id={'IBAN' + idx}
                  onBlur={e => this.handleIBANChange(e, idx)}
                  hidden={this.state.isMaganszemely ? this.getHidden('m_bankszamla') : false}
                  value={this.state.modositoObj && this.state.bankAdatok[idx].ibanszam}
                />
                <AvFeedback>
                  Kötelező mező!
                </AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-3">
              <Button
                color="danger"
                style={{ marginTop: '29px' }}
                type="button"
                onClick={() => this.deleteBankadat(idx)}
              >
                Törlés
              </Button>
              {' '}
            </div>
          </React.Fragment>
        );
      });
    }
    return (
      adatok
    );
  }

  handleHozzajarulElfogadDateChange = (e, v, idx) => {
    let currentValue = v === '' ? null : v;
    let newState = this.state.hozzajarulas;
    newState[idx].kezdete = currentValue;
    this.setState({ hozzajarulas: newState });
  }


  handleHozzajarulElfogadDateVegeChange = (e, v, idx) => {
    let currentValue = v === '' ? null : v;
    let newState = this.state.hozzajarulas;
    newState[idx].vege = currentValue;
    this.setState({ hozzajarulas: newState });
  }

  handleHozzajarulElfogadSelectChange = (e, v, idx) => {
    let currentValue = v;
    let newState = this.state.hozzajarulas;
    newState[idx].jogalapid = currentValue;
    this.setState({ hozzajarulas: newState });
  }

  handleHozzajarulElfogadCegadatChange = (e, v, idx) => {
    let currentValue = v === '' ? null : v;
    let newState = this.state.hozzajarulas;
    newState[idx].cegadatid = currentValue;
    this.setState({ hozzajarulas: newState });
  }

  deleteHozzajarulElfogad = (idx) => {
    let newState = this.state.hozzajarulas;
    newState.splice(idx, 1);
    this.setState({ hozzajarulas: newState });
  }

  dateTime = (date) => {
    let res = '';
    if (date === '') date = new Date();
    let honap = date.getMonth() + 1;
    let nap = date.getDate();
    if (honap < 10) honap = '0' + honap;
    if (nap < 10) nap = '0' + nap;
    res = date.getFullYear() + '-' + honap + '-' + nap;
    return res;
  }

  renderJogalapOptions = (item) => {
    return this.state.jogalap && this.state.jogalap.filter(x => !item.id && x.statusz === 'AKTIV' || x.id === item.jogalapid).map(e => (
       <option key={e.id} value={e.id}>
         {e.nev}
       </option>
     ));
 }

  renderCegadatOptions = (item) => {
    return this.state.cegadat && this.state.cegadat.filter(x => !item.id && x.aktiv || x.id === item.cegadatid).map(e => (
      <option key={e.id} value={e.id}>
        {e.nev + ' (' + e.tipus + ')'}
      </option>
    ));
  }

  renderHozzajarulasList = () => {
    let adatok = [];

    if (this.state.hozzajarulas) {
      this.state.hozzajarulas.forEach((item, idx) => {
        adatok.push(
          <React.Fragment key={idx.toString()}>
            <div className="col-md-3" id="jogalaplabel">
              <AvField
                type="select"
                name={'jogalap' + idx}
                id={'jogalap' + idx}
                label={(this.getRequired('m_gdpr') ? "Jogalap: *" : "Jogalap: ")}
                errorMessage="Kötelező mező!"
                onChange={(e, v) => {this.handleHozzajarulElfogadSelectChange(e, v, idx); this.validateCustomComponent(e, v, "jogalap" + idx, "jogalaperror", "jogalaplabel", "m_gdpr");}}
                onClick={(e, v) => this.validateCustomComponent(e, v, "jogalap" + idx, "jogalaperror", "jogalaplabel", "m_gdpr")}
                value={this.state.hozzajarulas[idx].jogalapid ? this.state.hozzajarulas[idx].jogalapid : ""}
                disabled={item.id ? true : false}
                required={this.state.isMaganszemely ? this.getRequired('m_gdpr') : false}
              >
                {!item.id && <option value="">
                  Válassz jogalapot...
                </option>}
                {this.renderJogalapOptions(item)}
              </AvField>
              <div id="jogalaperror" style={{ color: "red" }} hidden> Kötelező mező! </div>
            </div>
            <br />
            <div className="col-md-3">
              <AvField
                type="select"
                name={'cegadat' + idx}
                id={'cegadat' + idx}
                label={(this.getRequired('m_gdpr') ? "Kiállító: *" : "Kiállító: ")}
                errorMessage="Kötelező mező!"
                onChange={(e, v) => this.handleHozzajarulElfogadCegadatChange(e, v, idx)}
                value={this.state.hozzajarulas[idx].cegadatid !== '' ? this.state.hozzajarulas[idx].cegadatid : '-1'}
                disabled={item.id ? true : false}
                required={this.state.isMaganszemely ? this.getRequired('m_gdpr') : false}
              >
                {!item.id && <option disabled value="">
                  Válassz cégadatot...
                </option>}
                {this.renderCegadatOptions(item)}
              </AvField>
            </div>
            <div className="col-md-2">
              <Label for="kezdete">
                {this.getRequired('m_gdpr') ? "Kezdete: *" : "Kezdete: "}
              </Label>
              <DatePicker
                type="select"
                placeholder="éééé-hh-nn"
                name={'kezdete' + idx}
                value={(this.state.modositoObj || this.state.hozzajarulas[idx].kezdete !== '') && this.state.hozzajarulas[idx].kezdete !== '' ? new Date(this.state.hozzajarulas[idx].kezdete) : new Date()}
                disabled={item.id ? true : false}
                required={this.state.isMaganszemely ? this.getRequired('m_gdpr') : false}
                onChange={(e, v) => this.handleHozzajarulElfogadDateChange(e, v, idx)}
              />
            </div>
            {this.state.modositoObj && this.state.modositoObj.length !== 0 && item.id ? (
              <React.Fragment>
                <div className="col-md-2 ">
                  <Label for="vege">
                    Vége:
                  </Label>
                  <DatePicker
                    required
                    type="select"
                    name={'vege' + idx}
                    placeholder="éééé-hh-nn"
                    value={(this.state.modositoObj && this.state.hozzajarulas[idx].vege) && new Date(this.state.hozzajarulas[idx].vege)}
                    disabled={this.disabledHozzajarulasIds.includes(item.id)}
                    onChange={(e, v) => this.handleHozzajarulElfogadDateVegeChange(e, v, idx)}

                  />
                </div>
                <div className="col-sd-2">
                  <Button
                    color="primary"
                    style={{ marginTop: '29px' }}
                    type="button"
                    onClick={() => this.getVisszavonPdf(idx)}

                  >
                    PDF
                  </Button>
                  {' '}
                </div>
              </React.Fragment>
            ) : (
              <div className="col-md-3">
                <Button
                  color="danger"
                  style={{ marginTop: '29px' }}
                  type="button"
                  onClick={() => this.deleteHozzajarulElfogad(idx)}

                >
                  Törlés
                </Button>
                {' '}
              </div>
            )}
          </React.Fragment>
        );
      });
    }
    return (
      adatok
    );
  }

  renderFormButtons = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <ProgressButton
            color="primary"
            loading={this.state.mentesButtonLoading}
          >
            Mentés
          </ProgressButton>
          {' '}
          <Button type="button" color="secondary" onClick={this.navToList}>
            Mégse
          </Button>
        </div>
      </div>
    );
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  /* Modal hozzájárul Start */
  renderHozzajarulasModalList = () => {
    let adatok = [];
    if (this.state.hozzajarulasModalAdatok.length) {
      this.state.hozzajarulasModalAdatok[0].hozzajarulas.forEach((item, idx) => {
        if (!item.hozzajarul) {
          adatok.push(
            <React.Fragment key={idx.toString()}>
              <div className="col-md-3">
                <AvField
                  type="select"
                  disabled
                  name={'jogalap' + idx}
                  id={'jogalap' + idx}
                  label="Jogalap : "
                  errorMessage="Kötelező mező!"
                 // onChange={(e,v)=>this.handleHozzajarulElfogadSelectModalChange(e,v,idx)}
                  value={item.jogalapid && item.jogalapid}
                >
                  {this.state.jogalap.filter(x => x.id === item.jogalapid).map(e => (<option key={e.id} value={e.id}>{e.nev}</option>))}
                </AvField>
              </div>
              <div className="col-md-3">
                <AvField
                  type="select"
                  disabled
                  name={'cegadat' + idx}
                  id={'cegadat' + idx}
                  label="Kiállító : "
                  errorMessage="Kötelező mező!"
                 // onChange={(e,v)=>this.handleHozzajarulElfogadSelectModalChange(e,v,idx)}
                  value={item.cegadatid && item.cegadatid}
                >
                  {this.state.cegadat.filter(x => x.id === item.cegadatid).map(e => (<option key={e.id} value={e.id}>{e.nev + ' (' + e.tipus + ')'}</option>))}
                </AvField>
              </div>
              <div className="col-md-2">
                <Label for="kezdete">
                  Kezdete:
                </Label>
                <DatePicker
                  type="select"
                  disabled
                  name={'kezdete' + idx}
                  value={item.kezdete && new Date(item.kezdete)}
                  // onChange={(e,v)=>this.handleHozzajarulElfogadDateChangeModal(e,v,idx)}
                />
              </div>
              <div className="col-md-1">
                <Button
                  color="danger"
                  style={{ marginTop: '29px' }}
                  type="button"
                  onClick={() => this.pdfSend(idx)}
                >
                  PDF
                </Button>
                {' '}
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label check inline style={{ marginTop: '30px' }}>
                    <AvInput
                      type="checkbox"
                      id={'elfogadas' + idx}
                      name={'elfogadas' + idx}
                      value={item.hozzajarul ? item.hozzajarul : 'false'}
                      onChange={e => this.elFogadasCheckbox(e, idx)}
                    />
                    {' '}
                    Hozzájárul
                  </Label>
                </AvGroup>
              </div>
            </React.Fragment>
          );
        } else {
          this.navToList();
        }
      });
    }
    return (
      adatok
    );
  }

  pdfSend = (idx) => {
    if (this.state.modositoObj && this.state.modositoObj.id) {
      Services.pdfGen(this.state.modositoObj.id, this.state.hozzajarulasModalAdatok[0].hozzajarulas[idx].jogalapid, this.state.hozzajarulasModalAdatok[0].hozzajarulas[idx].cegadatid, this.state.hozzajarulasModalAdatok[0].hozzajarulas[idx].kezdete, null, (err, res) => {
        if (!err) {
          FileSaver.saveAs(new Blob([res[0]], { type: 'application/pdf' }), 'hozzajarulas');
          // let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }));
          // window.open(fileURL);
        }
      });
    } else {
      Services.pdfGen(this.state.hozzajarulasModalAdatok[0].id, this.state.hozzajarulas[idx].jogalapid, this.state.hozzajarulas[idx].cegadatid, this.state.hozzajarulasModalAdatok[0].hozzajarulas[idx].kezdete, null, (err, res) => {
        if (!err) {
          FileSaver.saveAs(new Blob([res[0]], { type: 'application/pdf' }), 'hozzajarulas');
          // let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }));
          // window.open(fileURL);
        }
      });
    }
  }

  getVisszavonPdf = (idx) => {
    Services.pdfGen(this.state.modositoObj.id, this.state.hozzajarulas[idx].jogalapid, null, this.state.hozzajarulas[idx].kezdete, this.state.hozzajarulas[idx].vege, (err, res) => {
      if (!err) {
        FileSaver.saveAs(new Blob([res[0]], { type: 'application/pdf' }), 'hozzajarulas');
        // let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }));
        // window.open(fileURL);
      }
    });
  }

  onDropObject = (acceptedfiles) => {
    if (!acceptedfiles){
      this.validateCustomComponent(null, null, "dropzone", "dropzoneerror", "dropzonelabel", this.state.isCeg ? "c_fajl" : "m_fajl");
    }
    acceptedfiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        let files = this.state.files || [];
        files.push({
          content: btoa(fileAsBinaryString),
          uploadDate: new Date(),
          docname: file.name,
          preview: file.preview,
          mime: file.type || 'application/octet-stream',
          id: null,
          length: file.size,
          preid: Lib.Browser.uuidv4()
        });
        this.setState({ files: files });
      };
      reader.readAsBinaryString(file);
    });
  }

  removeObject = (file) => {
    if (file.preid) {
      let files = this.state.files.filter(f => f.preid !== file.preid);
      this.setState({ files: files });
    } else {
      let files = this.state.files.filter(f => f.id !== file.id);
      this.setState({ files: files });
    }
  }

  downloadObject = (file) => {
    if (file.preview) {
      window.open(file.preview, '_blank');
    } else {
      this.getContent(file.id);
    }
  }

  getContent = (objid) => {
    let obj = this.state.files.find(f => f.id == objid);
    Services.downloadObject(objid, (err, res) => {
      if (!err) {
        FileSaver.saveAs(new Blob([res[0]], { type: obj.mime }), obj.docname);
      }
    });
  }

  toggleHozzajarulModal = () => {
    this.setState({
      modalHozzajarulModal: !this.state.modalHozzajarulModal
    });
  }

  partnerTorles = (id) => {
    Services.deletePartner(id, (err) => {
      if (!err) {
        this.props.route.globals.addSuccess('Partner módosítva!');
        this.toggleHozzajarulModal();
      }
    });
  }

  elFogadasCheckbox = (e, idx) => {
    let checkboxos = this.state.hozzajarulasModalAdatok;
    let bool = e.target.value;
    if (bool == 'false') bool = 'true'; else bool = 'false';
    this.state.hozzajarulasModalAdatok[0].hozzajarulas[idx].hozzajarul = bool;
  }

  hozzajarulKuld = () => {
    if (!this.state.modositoStatus) {
      Services.setHozzajarul(this.state.hozzajarulasModalAdatok[0].id, true, this.state.hozzajarulasModalAdatok[0].hozzajarulas, (err) => {
        if (!err) {
          this.props.route.globals.addSuccess('Partner felvéve!');
          this.navToList();
        }
      });
    } else {
      let mentesiAdatok = this.state.hozzajarulasModalAdatok[0].hozzajarulas;
      this.state.modositoObj.hozzajarulas.forEach((item) => {
        if (item.id) mentesiAdatok.push(item);
      });

      Services.setHozzajarul(this.state.modositoObj.id, false, mentesiAdatok, (err) => {
        if (!err) {
          this.props.route.globals.addSuccess('Partner módosítva!');
          this.navToList();
        }
      });
    }
  }

  formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' bájt';
    if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
    return (bytes / 1073741824).toFixed(3) + ' GB';
  };

  validateDropZone = () => {
    let dropzone = document.getElementById('dropzone');
    let error = document.getElementById('error');
    let label = document.getElementById('label');
    if (dropzone && error){
      if (this.state.files.length === 0 && (this.state.isCeg ? this.getRequired('c_fajl') : this.getRequired('m_fajl'))){
        dropzone.style.borderColor = 'red';
        error.hidden = false;
        label.style.color = 'red';
      } else {
        dropzone.style.borderColor = 'black';
        error.hidden = true;
        label.style.color = 'black';
      }
    }
  }


  validateCustomComponent = (e, v, componentid, errorid, labelid, required) => {
    let value = e;
    let component = document.getElementById(componentid);
    let error = document.getElementById(errorid);
    let label = document.getElementById(labelid);
   if (component && error && label) {
     if (componentid === "dropzone"){
      if (this.state.files.length === 0 && (this.state.isCeg ? this.getRequired('c_fajl') : this.getRequired('m_fajl'))){
        component.style.borderColor = 'red';
        error.hidden = false;
        label.style.color = 'red';
      } else {
        component.style.borderColor = 'black';
        error.hidden = true;
        label.style.color = 'black';
      }
     } else {
      if (this.getRequired(required) && value == null){
        let ertek = null;
        this.setState({ [componentid]: ertek });
        component.style.borderColor = 'red';
        error.hidden = false;
        label.style.color = 'red';
      } else {
        if ((this.getRequired(required) && component.value == "") || (this.getRequired(required) && component.value == -1)){
          component.style.borderColor = 'red';
          error.hidden = false;
          label.style.color = 'red';
        } else {
          if (value) {
            this.setState({ [componentid] : new Date(value) });
          }
          component.style.borderColor = 'grey';
          error.hidden = true;
          label.style.color = 'grey';
        }
      }
     }
  component.focus();
  }
  }

  handleSzulidoChange = (e) => {
    if (e == 'Invalid Date'){
      this.setState({  szuletesiido: null });
    } else {
      this.setState({ szuletesiido: e });
    }
  }

  renderFileUpload = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      <React.Fragment>
      <div className="row" hidden={this.state.isMaganszemely ? this.getHidden('m_fajl') : false }>
        <div className="col-md-12">
          <div>
            <h5 style={titleStyle} id="dropzonelabel">
              Fájl feltöltése: {(this.state.isCeg ? this.getRequired('c_fajl') : this.getRequired('m_fajl')) ? "*" : ""}
            </h5>
          </div>
        </div>
        <div className="col-md-12" id="dropzoneerror" style={{ color: 'red', fontSize: '15px' }} hidden><strong>Kötelező mező!</strong></div>
        <div className="col-md-12">
          <DropZone
            multiple
            id="dropzone"
            onDrop={this.onDropObject}
            className="custom-dropzone"
            onSelect={() => {this.validateCustomComponent(null, null, "dropzone", "dropzoneerror", "dropzonelabel", this.state.isCeg ? "c_fajl" : "m_fajl");}}
           onMouseOver={() => {this.validateCustomComponent(null, null, "dropzone", "dropzoneerror", "dropzonelabel", this.state.isCeg ? "c_fajl" : "m_fajl");}}
          >
            <div className="dropzone-text">
              Húzd ide a fájlokat vagy kattints a négyzetbe a fájl feltöltéséhez!
            </div>
          </DropZone>

        </div>

        {this.state.files && this.state.files.map((item, idx) => {
          return (
            <div className="col-md-4" key={idx.toString()}>
              <FileCard
                idx={idx.toString()}
                key={idx.toString()}
                title={item.docname}
                length={this.formatBytes(item.length)}
                onDownloadClick={() => this.downloadObject(item)}
                onDeleteClick={() => this.removeObject(item)}
              />
            </div>
          );
        })}

      </div>

      </React.Fragment>
    );
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  getRequired = (kotnev) => {
    let getRequired = false;
    if (this.state.mezokotelezosegek.length !== 0) {
      this.state.mezokotelezosegek.forEach((e) => {
        if (e.kotelezosegnev === kotnev) {
          getRequired = e.kotelezosegvalue;
        }
      });
    }
    return getRequired;
  }

  addStarToLabel = (id) => {
    let component = document.getElementById(id);
    if (component.required === true){
      return "*"
    } else {
      return ""
    }
  }

  getHidden = (kotnev) => {
    let getHidden = false;
    if (this.state.mezokotelezosegek.length !== 0) {
      this.state.mezokotelezosegek.forEach((e) => {
        if (e.kotelezosegnev === kotnev && e.kotelezosegvalue === null) {
          getHidden = true;
        }
      });
    }
    return getHidden;
  }

  render() {
    // console.log(this.state.mezokotelezosegek)
    const Option = ({ children, ...props }) => {
      const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
      const newProps = Object.assign(props, { innerProps: rest });
      return (
        <components.Option {...newProps}>
          <div className="flag-format" onClick={() => this.setValue(props.data)}>
            <span className={'flag-icon flag-icon-' + props.data.name.toLowerCase()} />
            {' '}
            <b>{props.data.caption}</b>
            {props.data.num ? ' +' : ''}
            {props.data.num}
          </div>
        </components.Option>
      );
    };

    const {
      formModalTipus,
      formModalOrszaghivo,
      formModalTelefonszam
    } = this.state;
    let cardStyle = {
      padding: 20
    };
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              {this.state.modositoStatus ? 'Partner módosítása' : 'Új partner' }
            </div>
            <div style={cardStyle}>
              {this.state.dataLoaded ? (
                <AvForm onValidSubmit={this.mentes} onInvalidSubmit={this.handleInvalidSubmit} id="avform">
                  {this.renderAlapAdatok()}
                  {this.renderHivatalosCim()}
                  {this.renderSzallitasiCimek()}
                  {this.renderSzamlazasiCimek()}
                  {this.renderTelefonszamMezok()}
                  {this.renderEgyebAdatok()}
                  {this.renderBankAdatok()}
                  {this.renderKiegeszitoAdatok()}
                  {this.renderHozzajarulasAdatok()}
                  {/* {this.renderElerhetosegek()}
                  {this.renderEgyebAdatok()}
                  {this.renderBankAdatok()}
                  {this.renderKiegeszitoAdatok()}
                  {this.renderHozzajarulasAdatok()} */}
                  {this.renderFormButtons()}
                </AvForm>
              ) : this.renderLoader() }
              <Modal
                isOpen={this.state.modalHozzajarulModal}
                toggle={this.toggleHozzajarulModal}
                className={this.props.className}
                backdrop="static"
                size="lg"
              >
                <AvForm onValidSubmit={this.hozzajarulKuld}>
                  <ModalHeader>
                    {'Hozzájárulás elfogadása'}
                  </ModalHeader>
                  <ModalBody>
                    <div className="row">
                      <div className="col-md-12">
                        <div>
                          <h5 style={titleStyle}>
                            {'Hozzájárulások:'}
                          </h5>
                        </div>
                      </div>
                      {this.renderHozzajarulasModalList()}
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ProgressButton
                      color="primary"
                    >
                      {'Mentés'}
                    </ProgressButton>
                    {' '}
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => { this.state.modositoObj ? this.toggleHozzajarulModal() : this.partnerTorles(this.state.hozzajarulasModalAdatok.length != 0 && this.state.hozzajarulasModalAdatok[0].id); }}
                    >
                      {'Mégse'}
                    </Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
              <Modal isOpen={this.state.modalPhone} toggle={this.togglePhoneModal} backdrop="static">
                <AvForm onValidSubmit={this.addPhone}>
                  <ModalHeader>{this.state.modalPhoneTitle}</ModalHeader>
                  <ModalBody>
                    <div>
                      <AvField
                        type="select"
                        name="formModalTipus"
                        id="formModalTipus"
                        label="Típus: *"
                        errorMessage="Kötelező mező!"
                        value={formModalTipus}
                        onChange={this.handleInputChange}
                        required={this.state.isCeg ? this.getRequired('c_tel') : this.getRequired('m_tel')}
                      >
                        <option value="">
                          {'Válassz típust...'}
                        </option>
                        {this.state.telefonTipusOptions}
                      </AvField>
                    </div>
                    <div className="form-group">
                      <Label for="formModalOrszaghivo">
                        {'Országhívó: *'}
                      </Label>
                      {/* <Select
                        name={'formModalOrszaghivo'}
                        id={'formModalOrszaghivo'}
                        optionComponent={props => this.flagFormatter(props)}
                        options={this.state.orszagOptions}
                        clearable={false}
                        searchable={false}
                        onChange={this.setValue}
                        placeholder="Válassz országhívót..."
                        noResultsText="Nincs keresési eredmény"
                        searchPromptText="Válassz országhívót..."
                        loadingPlaceholder="Töltés..."
                        valueKey="num"
                        labelKey="num"
                        value={formModalOrszaghivo}
                      /> */}
                      <SelectInput
                        name="formModalOrszaghivo"
                        id="formModalOrszaghivo"
                        // components={{
                        //   Option: props => (
                        //     this.flagFormatter(props.data, props)
                        //   )
                        // }}
                        components={{ Option }}
                        isSearchable={false}
                        placeholder="Válassz országot..."
                        value={formModalOrszaghivo}
                        getOptionLabel={option => option.caption}
                        getOptionValue={option => option}
                        options={this.state.orszagOptions}
                        required={this.state.isCeg ? this.getRequired('c_tel') : this.getRequired('m_tel')}
                        // styles={colourStyles}
                      />
                    </div>
                    <div>
                      <AvGroup>
                        <Label for="formModalTelefonszam">
                          {'Telefonszám: *'}
                        </Label>
                        <AvInput
                          name="formModalTelefonszam"
                          id="formModalTelefonszam"
                          required={this.state.isCeg ? this.getRequired('c_tel') : this.getRequired('m_tel')}
                          value={formModalTelefonszam}
                          onChange={this.handleInputChange}
                        />
                        <AvFeedback>
                          {'Kötelező mező!'}
                        </AvFeedback>
                      </AvGroup>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ProgressButton color="primary" loading={this.state.phoneButtonLoading}>{this.state.modalPhoneButtonText}</ProgressButton>
                    <Button type="button" color="secondary" onClick={this.togglePhoneModal}>Mégsem</Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnertorzsUrlap;
