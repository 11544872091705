import Lib from '../../../commons/lib';

export default class Service {
  static szamlazasUrl = window.location.origin + '/api/udulesijogszla/';

  static gdprUrl = window.location.origin + '/api/gdpr/';

  static partnerUrl = window.location.origin + '/api/partner/';

  // FIZETÉSI FELSZÓLÍTÓ PROFIL START

  static getGDPRprofil(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'gdprprofil',
      args: { requestType: 'partner-gdprprofil-get' }
    }], fnDone);
  }

  static check1(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'gdprprofil/check1',
      args: { requestType: 'partner-gdprprofil-check1' },
      data: data
    }], fnDone);
  }

  static check2modify(data, ker, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'gdprprofil/check2modify',
      args: { requestType: 'partner-gdprprofil-check2modify' },
      data: data,
      params: { ker: ker },
      acceptType: 'text/plain;charset=utf-8',
    }], fnDone);
  }

  // static updateGDPRprofil(data, fnDone) {
  //   Lib.MicroServices.requestService([{
  //     method: 'PUT',
  //     url: Service.partnerUrl + 'gdprprofil/modify',
  //     args: { requestType: 'partner-gdprprofil-put' },
  //     data: data
  //   }], fnDone);
  // }

  static updateProfilFelso(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'parameter',
      args: { requestType: 'parameter-profil' },
      data: data
    }], fnDone);
  }

  static updateProfilAlso(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'parameter',
      args: { requestType: 'parameter-profil' },
      data: data
    }], fnDone);
  }

  static getProfilAlso(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'parameter/string/nbyn',
      args: { requestType: 'munkalap-profil-base' },
      data: ['KKAMAT_PROFIL_ALSO'],
    }], fnDone);
  }

  // FIZETÉSI FELSZÓLÍTÓ PROFIL END

  // JOGALAP START

  static listJogalap(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'jogalap/list',
      args: { requestType: 'jogalap-list' },
    }], fnDone);
  }

  static deleteJogalap(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.gdprUrl + 'jogalap',
      args: { requestType: 'jogalap-delete' },
      params: { id: id }
    }], fnDone);
  }

  static addJogalap(jogalapObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.gdprUrl + 'jogalap',
      args: { requestType: 'jogalap-post' },
      data: jogalapObj
    }], fnDone);
  }

  static getJogalap(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'jogalap',
      args: { requestType: 'jogalap-get' },
      params: { id: id }
    }], fnDone);
  }

  static editJogalap(jogalapObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.gdprUrl + 'jogalap',
      args: { requestType: 'jogalap-put' },
      data: jogalapObj,
    }], fnDone);
  }

  // JOGALAP END

  // ÖSSZERENDELÉS START

  static listOsszerendeles(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'funkciojogalap/list',
      args: { requestType: 'funkciojogalap-list' },
    }], fnDone);
  }

  static getOsszerendeles(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'funkciojogalap',
      args: { requestType: 'funkciojogalap-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteOsszerendeles(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.gdprUrl + 'funkciojogalap',
      args: { requestType: 'funkciojogalap-delete' },
      params: { id: id }
    }], fnDone);
  }

  static editOsszerendeles(osszerendelesObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.gdprUrl + 'funkciojogalap',
      args: { requestType: 'funkciojogalap-put' },
      data: osszerendelesObj,
    }], fnDone);
  }

  static addOsszerendeles(osszerendelesObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.gdprUrl + 'funkciojogalap',
      args: { requestType: 'funkciojogalap-post' },
      data: osszerendelesObj
    }], fnDone);
  }

  static listAktivFunkcio(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'funkcio/list',
      args: { requestType: 'funkcio-list' },
    }], fnDone);
  }

  static listAktivJogalap(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.gdprUrl + 'jogalap/aktiv',
      args: { requestType: 'jogalap-aktiv' },
      params: { bovitett: true }
    }], fnDone);
  }

  // ÖSSZERENDELÉS END
}
